<template>
  <div class="col s12">
    <div
      class="col s12 brd-5 pg-10"
      v-if="isniForm.donneeIdentification.variante.idVariante3.check"
    >
      <div class="input-field col s12 m6 l6">
        <input
          id="NvarianteTrois"
          type="text"
          class="validate"
          v-model="isniForm.donneeIdentification.variante.idVariante3.nom"
        />
        <label for="NvarianteTrois" v-bind:class="{ error: hasError, active: !isniForm.donneeIdentification.variante.idVariante3.nom == '' }">
          {{ dataText.e2PVariantenomInputNomLabel }}
          <span class="red-text">*</span>
        </label>
      </div>
      <div class="input-field col s12 m6 l6">
        <input
          id="PvarianteTrois"
          type="text"
          class="validate"
          v-model="isniForm.donneeIdentification.variante.idVariante3.prenom"
        />
        <label for="PvarianteTrois" :class="{active: !isniForm.donneeIdentification.variante.idVariante3.prenom == ''}">{{
          dataText.e2PVariantenomInputPrenomLabel
        }}</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VarianteNom3",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    hasError: Boolean
  }
};
</script>
