<template>
  <!-- {{RELATION 3}} -->
  <div class="col s12">
    <div class="col s12 l12 brd-5 pg-10 mg-10">
      <div class="input-field col s12 m8 l4">
        <select
          v-model="isniForm.relation.idPersonne2.typeRelation"
          name="TdemandeurPerso2"
        >
          <option value disabled selected>{{ dataText.eCListeTexteParDefaut }}</option>
          <option
            v-for="(ref, index) in dataSelect.RefTypeDemandeurPersRelationPers"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
        <label :class="{ error: hasError }" for="TdemandeurPerso2">
          {{ dataText.e4CRelationpersonneListeTyperelationLabel }}
          <span class="red-text">*</span>
          <Isni-Tooltip :content="dataText.e4CRelationpersonneListeTyperelationAc">
            <icon iconText="help"/>
          </Isni-Tooltip>
        </label>
      </div>
      <div class="input-field col s12 m6 l4">
        <input
          id="RelationNfamille2"
          type="text"
          class="validate"
          v-model="isniForm.relation.idPersonne2.nom"
        />
        <label
          for="RelationNfamille2"
          :class="{ error: hasError, active: !isniForm.relation.idPersonne2.nom == ''}"
        >
          {{ dataText.e4CRelationpersonneInputNomLabel
          }}<span class="red-text">*</span>
          <Isni-Tooltip :content="dataText.e4CRelationpersonneInputNomAc">
            <icon iconText="help"/>
          </Isni-Tooltip>
        </label>
      </div>
      <div class="input-field col s12 m6 l4">
        <input
          id="Rprenom2"
          type="text"
          class="validate"
          v-model="isniForm.relation.idPersonne2.prenom"
        />
        <label
          for="Rprenom2"
          :class="{ active: !isniForm.relation.idPersonne2.prenom == '' }"
        >
          {{ dataText.e4CRelationpersonneInputPrenomLabel }}</label
        >
      </div>
    </div>
  </div>
  <!-- {{RELATION 3}} -->
</template>
<script>
import Icon from "./../../elements/Icon";
import IsniTooltip from "../../elements/IsniTooltip";

export default {
  name: "RelationPerso2",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    hasError: Boolean
  },
  data() {
    return {};
  },
  components: { Icon, IsniTooltip }
};
</script>
