<template>
  <div class="col s12">
    <div class="col s12 brd-5 pg-10">
      <div class="input-field col s12 m6 l6">
        <input
          id="InomTrois"
          type="text"
          class="validate"
          v-model="
            isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept2
              .associeNom
          "
        />
        <label
          for="InomTrois"
          :class="{
            error: hasError,
            active:
              !isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept2
                .associeNom == ''
          }"
          >{{ dataText.e2CIdassocienomInputIdLabel }}
          <span class="red-text">*</span></label
        >
      </div>

      <div class="input-field col s12 m6 l6">
        <select
          id="ItypeTrois"
          v-model="
            isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept2
              .associeNomType
          "
        >
          <option value disabled selected>{{ dataText.eCListeTexteParDefaut }}</option>
          <option
            v-for="(ref, index) in dataSelect.RefTypeAutreIdentifiant"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
        <label :class="{ error: hasError }">
          {{ dataText.e2CIdassocienomListeTypeidLabel }}
          <span class="red-text">*</span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "IdentifiantNom2",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    hasError: Boolean
  }
};
</script>
