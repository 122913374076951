<template>
  <div class="col s12">
    <!-- {{ relation orga 2}} -->

    <div class="col s12 brd-5 pg-10">
      <div class="input-field col s12 m6 l6">
        <select
          v-model="isniForm.relation.idOrganisme1.typeRelation"
          name="Torganisme1"
        >
          <option value disabled selected>{{
            dataText.eCListeTexteParDefaut
          }}</option>
          <option
            v-for="(ref, index) in dataSelect.RefTypeDemandeurPersRelationOrga"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
        <label :class="{ error: hasError }" for="Torganisme1">
          {{ dataText.e4CRelationorganismeListeTyperelationLabel }}
          <span class="red-text">*</span>
          <Isni-Tooltip :content="dataText.e4CRelationorganismeListeTyperelationAc">
            <icon iconText="help"/>
          </Isni-Tooltip>
        </label>
      </div>
      <div class="input-field col s12 m6 l6">
        <input
          id="RnomOrga1"
          type="text"
          class="validate"
          v-model="isniForm.relation.idOrganisme1.nomOrganisme"
        />
        <label
          for="RnomOrga1"
          :class="{ error: hasError, active: !isniForm.relation.idOrganisme1.nomOrganisme == ''}"
        >
          {{ dataText.e4CRelationorganismeInputNomLabel }}
          <span class="red-text">*</span>
          <Isni-Tooltip :content="dataText.e4CRelationpersonneInputNomAc">
            <icon iconText="help"/>
          </Isni-Tooltip>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./../../elements/Icon";
import IsniTooltip from "./../../elements/IsniTooltip";

export default {
  name: "RelationOrgaPerso1",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    hasError: Boolean
  },
  components: { Icon, IsniTooltip }
};
</script>
