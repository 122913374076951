<template>
  <div class="col s12">
    <div
      class="col s12 brd-5 pg-10"
      v-if="isniForm.donneeIdentification.variante.idVariante4.check"
    >
      <div class="input-field col s12 m6 l6">
        <input
          id="NvarianteQuatre"
          type="text"
          class="validate"
          v-model="isniForm.donneeIdentification.variante.idVariante4.nom"
        />
        <label for="NvarianteQuatre" v-bind:class="{ error: hasError, active: !isniForm.donneeIdentification.variante.idVariante4.nom == '' }">
          {{ dataText.e2PVariantenomInputNomLabel }}
          <span class="red-text">*</span>
        </label>
      </div>
      <div class="input-field col s12 m6 l6">
        <input
          id="PvarianteQuatre"
          type="text"
          class="validate"
          v-model="isniForm.donneeIdentification.variante.idVariante4.prenom"
        />
        <label for="PvarianteQuatre" :class="{active: !isniForm.donneeIdentification.variante.idVariante4.prenom == ''}">{{
          dataText.e2PVariantenomInputPrenomLabel
        }}</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VarianteNom4",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    hasError: Boolean
  }
};
</script>
