<template>
  <div class="col s12">
    <div
      class="col s12 brd-5 pg-10"
      v-show="isniForm.oeuvre.idOeuvre3.ajoutCheck"
    >
      <!-- {{ oeuvre }} -->
      <div class="input-field col s12 m6 l6">
        <select
          v-model="isniForm.oeuvre.idOeuvre3.typeOeuvre"
          name="Tthematique3"
        >
          <option value disabled selected>{{
            dataText.eCListeTexteParDefaut
          }}</option>
          <option
            v-for="(ref, index) in dataSelect.RefTypeOeuvre"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
        <label v-bind:class="{ error: hasError }" for="Tthematique3">
          {{ dataText.e3CInfosoeuvreListeTypeLabel }}
          <span class="red-text">*</span>
          <Isni-Tooltip :content="dataText.e3CInfosoeuvreListeTypeAc">
            <icon iconText="help" />
          </Isni-Tooltip>
        </label>
      </div>
      <!-- {{ role oeuvre }} -->
      <!-- {{ role oeuvre }} -->
      <div class="input-field col s12 m6 l6">
        <select v-model="isniForm.oeuvre.idOeuvre3.roleOeuvre" name="Orole3">
          <option value disabled selected>{{
            dataText.eCListeTexteParDefaut
          }}</option>
          <option
            v-for="(ref, index) in dataSelect.RefRoleDemandeur"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
        <label :class="{ error: hasError }" for="Orole3">
          {{ dataText.e3CInfosoeuvreListeRoleLabel }}
          <span class="red-text">*</span>
        </label>
      </div>
      <!-- {{ thematique oeuvre }} -->
      <div class="input-field col s12 m12 l12">
        <select
          v-model="isniForm.oeuvre.idOeuvre3.thematiqueOeuvre"
          name="Otheme3"
        >
          <option value disabled selected>{{
            dataText.eCListeTexteParDefaut
          }}</option>
          <option
            v-for="(ref, index) in dataSelect.RefThematique"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
        <label for="Otheme3" :class="{ error: hasError }">
          {{ dataText.e3CInfosoeuvreListeThematiqueLabel }}
          <span class="red-text">*</span>
          <Isni-Tooltip :content="dataText.e3OInfosoeuvreListeThematiqueAc">
            <icon iconText="help" />
          </Isni-Tooltip>
        </label>
      </div>

      <div class="col s12">
        <p class="light-blue-text text-darken-4">
          {{ dataText.e3PInfosoeuvreSoustitreTexte }}
        </p>
        <p>
          <label>
            <input
              name="group3"
              type="radio"
              :value="true"
              class="with-gap"
              v-model="isniForm.oeuvre.idOeuvre3.indOeuvreCollective"
            />
            <span class="black-text fw-400">
              {{ dataText.e3PInfosoeuvreRb1Texte }}
            </span>
          </label>
        </p>
        <p>
          <label>
            <input
              name="group3"
              type="radio"
              class="with-gap"
              :value="false"
              v-model="isniForm.oeuvre.idOeuvre3.indOeuvreCollective"
            />
            <span class="black-text fw-400">{{
              dataText.e3PInfosoeuvreRb2Texte
            }}</span>
          </label>
        </p>
      </div>

      <!-- {{ si oui }} -->
      <div
        class="col s12"
        v-show="isniForm.oeuvre.idOeuvre3.indOeuvreCollective == true"
      >
        <div class="input-field col s12 m8 l8">
          <input
            id="ToeuvreCollective3"
            type="text"
            class="validate"
            v-model="isniForm.oeuvre.idOeuvre3.titreOeuvreCollective"
          />
          <label
            for="ToeuvreCollective3"
            v-bind:class="{
              error: hasError,
              active: !isniForm.oeuvre.idOeuvre3.titreOeuvreCollective == ''
            }"
          >
            {{ dataText.e3PInfosoeuvreInputTitreoeuvrecollaborativeLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip
              :content="dataText.e3PInfosoeuvreInputTitreoeuvrecollaborativeAc"
            >
              <icon iconText="help" />
            </Isni-Tooltip>
          </label>
        </div>
      </div>

      <!-- {{ si nom }} -->
      <div
        class="col s12"
        v-show="isniForm.oeuvre.idOeuvre3.indOeuvreCollective == false"
      >
        <div class="input-field col s12 m6 l6">
          <input
            id="ToeuvrePeriodique3"
            type="text"
            class="validate"
            v-model="isniForm.oeuvre.idOeuvre3.titreOeuvre"
          />
          <label
            for="ToeuvrePeriodique3"
            v-bind:class="{
              error: hasError,
              active: !isniForm.oeuvre.idOeuvre3.titreOeuvre == ''
            }"
          >
            {{ dataText.e3CInfosoeuvreInputTitreoeuvreLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip
              :content="dataText.e3CInfosoeuvreInputSoustitreoeuvreAc"
            >
              <icon iconText="help" />
            </Isni-Tooltip>
          </label>
        </div>
        <div class="input-field col s12 m6 l6">
          <input
            id="StitreOeuvre3"
            type="text"
            class="validate"
            v-model="isniForm.oeuvre.idOeuvre3.sousTitreOeuvre"
          />
          <label
            for="StitreOeuvre3"
            :class="{
              active: !isniForm.oeuvre.idOeuvre3.sousTitreOeuvre == ''
            }"
          >
            {{ dataText.e3CInfosoeuvreInputSoustitreoeuvreLabel }}
            <Isni-Tooltip
              :content="dataText.e3CInfosoeuvreInputSoustitreoeuvreAc"
            >
              <icon iconText="help" />
            </Isni-Tooltip>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "./../../elements/Icon";
import IsniTooltip from "../../elements/IsniTooltip";

export default {
  name: "ouvre3",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    hasError: Boolean
  },
  data() {
    return {};
  },
  components: { Icon, IsniTooltip }
};
</script>
