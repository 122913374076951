<template>
  <div class="row-isni--content row">
    <div v-for="(dataText, index) in textFormulaire" :key="index">
      <div class="wrapper-isni--content col s12">
        <app-step-progress
          :length="4"
          :propsStep="step"
          :dataText="dataText"
          :title-step="dataText.e1PTitre"
        ></app-step-progress>

        <section class="wrapper-form col s12">
          <app-list-error
            :errors="errors"
            :dataText="dataText"
          ></app-list-error>

          <form
            v-for="(isniForm, index) in isniForms"
            :key="index"
            class="col s12"
          >
            <!-- STEP 1 -->
            <section class="step1" v-if="step == 1">
              <h3 class="light-blue-text text-darken-4">
                {{ dataText.e1CFormulaireTitre }}
              </h3>
              <!-- STEP -->
              <app-step-1
                v-bind:isniForm="isniForm"
                :hasError="hasError"
                :dataText="dataText"
                :dataSelect="dataSelect"
              ></app-step-1>
            </section>

            <!-- STEP 2 -->
            <section class="step2" v-if="step == 2">
              <app-step-2
                v-bind:isniForm="isniForm"
                :hasError="hasError"
                :errorDetail="errorDetail"
                :dataText="dataText"
                :dataSelect="dataSelect"
              ></app-step-2>
            </section>

            <!-- STEP 3 -->
            <section class="step3" v-if="step == 3">
              <app-step-3
                :isniForm="isniForm"
                :hasError="hasError"
                :dataText="dataText"
                :dataSelect="dataSelect"
              >
              </app-step-3>
            </section>

            <!-- STEP 4 -->
            <section class="step4" v-if="step == 4">
              <app-step-4
                :isniForm="isniForm"
                :hasError="hasError"
                :dataText="dataText"
                :dataSelect="dataSelect"
              >
              </app-step-4>
            </section>

            <!-- ButtonStep  -->

            <div class="wrapper-btn col s12 offset-m2 offset-l3 l10">
              <button
                id="backHome"
                type="button"
                class="btn waves-effect waves-light col s12 m4 l3 mt-5 mr-5 white-text amber darken-2 h-btn-amber"
                v-if="step == 1"
                @click="BackHome"
              >
                {{ dataText.eCCtaRetourLabel }}
                <!-- <router-link to="/" class="white-text">Retour</router-link> -->
              </button>
              <button
                type="button"
                id="previousStep"
                class="btn waves-effect light-blue waves-light col s12 m4 l3 mt-5 h-btn-darken"
                v-if="step != 1"
                @click.prevent="previousStep"
                v-bind:class="{
                  disabled:
                    !isniForm.consentement.admissible ||
                    !isniForm.consentement.information ||
                    !isniForm.consentement.demandeIsni,
                }"
              >
                <a href="#isni-form" class="white-text">
                  <span class="d-flex end">
                    <i class="material-icons">keyboard_arrow_left</i>
                    {{ dataText.eCCtaPrecedentLabel }}
                  </span>
                </a>
              </button>
              <button
                type="button"
                id="nextStep"
                class="btn waves-effect waves-light blue darken-4 col offset-m1 offset-l1 s12 m4 l3 mt-5 h-btn-light"
                v-if="step != totalStep"
                @click.prevent="nextStep"
                :disabled="activButtonStep1"
                :class="{ disabled: activButtonStep1 }"
              >
                <a href="#isni-form" class="white-text">
                  <span class="d-flex end">
                    {{ dataText.eCCtaSuivantLabel }}
                    <i class="material-icons">keyboard_arrow_right</i>
                  </span>
                </a>
              </button>

              <button
                type="button"
                id="sendForm"
                class="btn waves-effect waves-light green col offset-m1 offset-l1 s12 m4 l4 mt-5 h-btn-green"
                v-if="step == 4"
                @click.prevent="sendForm"
                :disabled="activButtonStep4"
                :class="{ disabled: activButtonStep4, disabled: isSending }"
              >
                <a href="#isni-form" class="white-text">
                  <span class="d-flex end">
                    {{ dataText.e4CCtaEnvoyerLabel }} &nbsp;
                    <i class="material-icons ml-5">send</i>
                  </span>
                </a>
              </button>
            </div>
          </form>
        </section>
      </div>

      <div class="row">
        <conditions :step="step" :dataText="dataText" />
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import Step1 from "./../components/form/steps/Step-1";
import Step2 from "./../components/form/steps/Step-2";
import Step3 from "./../components/form/steps/Step-3";
import Step4 from "./../components/form/steps/Step-4";
import StepProgress from "./../components/layout/StepProgress";
import ListErrors from "./../components/form/elements/ListErrors";
import Conditions from "./../components/form/elements/Conditions";
// @ is an alias to /src
import { Constants } from "@/constants";
//  Recupere les data du select
import { postApiService } from "@/services/postApi.service";
import { validateDateService } from "@/services/validateDate.service";
import { helperDataFormService } from "@/services/helperDataForm.service";

export default {
  name: "Form",
  components: {
    "app-step-1": Step1,
    "app-step-2": Step2,
    "app-step-3": Step3,
    "app-step-4": Step4,
    "app-step-progress": StepProgress,
    "app-list-error": ListErrors,
    Conditions,
  },
  props: {
    isniForms: {
      type: Object,
      required: true,
    },
    dataSelect: {
      type: Object,
      required: true,
    },
    textErreur: {
      type: Object,
      required: true,
    },
    textFormulaire: {
      type: Object,
      required: true,
    },
    jumpToStep: {
      type: Number,
      required: false,
    },
    validateEmail: {
      type: Boolean,
      required: false,
    },
  },
  data: function () {
    return {
      titleForm: "Demande d'ISNI pour une personne",
      step: 1, // initialisation des steps
      totalStep: 4,
      hasError: false,
      errorDetail: {},
      errors: [],
      displayTitleOrganisme: false,
      displayTitlePersonne: true,
      objSelect: {},
      json: [],
      dateNaissance: null,
      dateDeces: null,
      yearLimit: new Date().getFullYear(),
      typeForm: Constants.TYPE_FORM.perso,
      isSending: false,
    };
  },
  methods: {
    /**
     *
     * gestion du retour au top du formulaire (SPA)
     */
    goToTopForm() {
      return window.scrollTo(0, 200);
    },
    /**
     * gestion des Step
     * Validation des champs obigatoire
     */
    nextStep: function () {
      // retour haut du formulaire
      this.goToTopForm();
      // tableau des erreurs
      this.errors = [];
      this.errorDetail = {};

      //gestion afficahge des erreurs si il y en a ou pas
      if (!this.errors.length) {
        this.hasError = false;
      }

      // nettoyer les champs pas utilisées (helperDataForm)
      helperDataFormService.cleanupHelperDataForm(
        this.typeForm,
        this.isniForms.isniForms
      );

      // Gestion des erreurs sur les steps
      // Si les champs est vide ou incorrect alors on affiche l'erreur
      if (this.step === 1) {
        this.getErrorsStep1();
      } else if (this.step === 2) {
        this.getErrorsStep2();
      } else if (this.step === 3) {
        this.getErrorsStep3();
      }

      // validation si pas d'erreur on passe a la next step
      if (!this.errors.length) {
        if (this.step === 1 && this.validateEmail) {
          this.sendEmailValidation();
        } else {
          this.step++;
        }
        return true;
      }
      if (this.errors.length > 0) {
        return false;
      }
      // avance dans les steps
      this.step++;
    },
    /**
     * Step precedente
     */
    previousStep: function () {
      // au retour de step on vide le tableau d'erreur
      this.errors = [];
      this.errorDetail = {};
      // re-initialise l'affichage des erreurs
      this.hasError = false;
      this.goToTopForm();
      this.step--;
    },
    //******* Envoie du formulaire */
    sendForm: function () {
      // gestion erreur step4
      this.getErrorsStep4();
      // si pas d'erreur
      if (!this.errors.length) {
        // appel du service post
        this.postForm();
        this.isSending = true;
      } else {
        // retour haut du formulaire
        this.goToTopForm();
      }
    },

    /**
     * Services
     */
    async postForm() {
      // return await postApiService.postForm(this.isniForms);

      const postFormResult = await postApiService.postForm(this.isniForms);
      if (postFormResult && postFormResult.data) {
        const codeMsgForm = postFormResult.data.codeMsgForm;
        const paramsMsgForm = postFormResult.data.paramsMsgForm;
        this.$router.push({
          name: "Merci",
          params: { codeMsgForm: codeMsgForm, paramsMsgForm: paramsMsgForm },
        });
      } else {
        this.$router.push({
          name: "Merci",
          params: {
            codeMsgForm: "nCProbTechEnvoiFormulaireTexte",
            paramsMsgForm: {},
          },
        });
      }
    },
    async sendEmailValidation() {
      const emailValidationSentResult = await postApiService.sendEmailValidation(
        this.isniForms.isniForms.demandeur,
        this.typeForm
      );
      let messageKey = "nCProbEnvoiCourrielValidationTexte";
      if (
        emailValidationSentResult &&
        emailValidationSentResult.status == 200
      ) {
        messageKey = "nCEnvoiCourrielValidationTexte";
      }
      this.$router.push({
        name: "emailValidationSent",
        params: { messageKey: messageKey },
      });
    },

    /**
     ** Retour /
     */
    BackHome: function () {
      this.$router.push("/");
    },
    /**
     * validation de l'adresse email
     */
    validEmail(email) {
      /* eslint-disable-next-line */
      var reg = new RegExp(
        /* eslint-disable-next-line */
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      );
      return reg.test(email);
    },
    /**
     * Gestion des erreurs dans la step1
     */
    getErrorsStep1() {
      // vérification des caractères non latins
      if (
        !this.isLatinCode(JSON.stringify(this.isniForms.isniForms.demandeur))
      ) {
        this.errors.push(this.textErreur.formTexteLatinErreur);
        this.hasError = true;
      } else {
        // Si les champs est vide ou incorrect alors on affiche l'erreur

        if (!this.isniForms.isniForms.demandeur.langueFormulaire) {
          this.errors.push(this.textErreur.e1CListeLangueErreur);
          this.hasError = true;
        }
        /**
         ** Validation de l'adresse email
         */
        // si il est vide
        if (
          this.isniForms.isniForms.demandeur.email == null ||
          this.isniForms.isniForms.demandeur.email == ""
        ) {
          // this.errors.push("Veuillez saisir l'adresse courriel.");
          this.errors.push(this.textErreur.e1CInputCourrielErreur1);
          this.hasError = true;
          // validation email si le format n'est pas le bon
        } else if (!this.validEmail(this.isniForms.isniForms.demandeur.email)) {
          // this.errors.push("Veuillez saisir une adresse courriel valide.");
          this.errors.push(this.textErreur.e1CInputCourrielErreur2);
        }
      }
    },
    /**
     * Gestion des erreurs dans la step2
     */
    getErrorsStep2() {
      // vérification des caractères non latins
      if (
        !this.isLatinCode(
          JSON.stringify(this.isniForms.isniForms.donneeIdentification)
        ) ||
        !this.isLatinCode(
          JSON.stringify(this.isniForms.isniForms.reelNomOuPseudo)
        )
      ) {
        this.errors.push(this.textErreur.formTexteLatinErreur);
        this.hasError = true;
      } else {
        // Si les champs est vide ou incorrect alors on affiche l'erreur

        // nom
        if (!this.isniForms.isniForms.donneeIdentification.diPersoNom) {
          this.errors.push(
            this.textErreur.e2PIdpubliqueTypeNomreelInputNomErreur
          );
          this.hasError = true;
        }
        // genre
        if (!this.isniForms.isniForms.donneeIdentification.diPersoGenre) {
          this.errors.push(this.textErreur.e2PIdpubliqueTypeListeGenreErreur);
          this.hasError = true;
        }
        // nationalite
        if (
          this.isniForms.isniForms.donneeIdentification.diPersoNationalite
            .length < 1
        ) {
          this.errors.push(
            this.textErreur.e2PIdpubliqueTypeListeNationaliteErreur
          );
          this.hasError = true;
        }
        // nationalite
        if (
          this.isniForms.isniForms.donneeIdentification.diPersoNationalite
            .length > 3
        ) {
          this.errors.push(
            this.textErreur.e2PIdpubliqueTypeListeNationaliteErreur
          );
          this.hasError = true;
        }

        // validate date 'naissance'
        const resultValidateDateNaissance = validateDateService.checkIndividualDate(
          this.isniForms.isniForms.donneeIdentification.date.naissance,
          ">=",
          "DateNaissance"
        );
        const _DateNaissance = resultValidateDateNaissance.fullDate || null;
        if (
          resultValidateDateNaissance.error &&
          resultValidateDateNaissance.error.hasError
        ) {
          const errorLabelKeys =
            resultValidateDateNaissance.error.errorLabelKeys;
          this.hasError = true;
          this.errorDetail.naissanceAll = true;
          if (this.textErreur) {
            for (let i = 0; i < errorLabelKeys.length; i++) {
              this.errors.push(this.textErreur[errorLabelKeys[i]]);
            }
          }
        }

        // validate date 'deces'
        const resultValidateDateDeces = validateDateService.checkIndividualDate(
          this.isniForms.isniForms.donneeIdentification.date.deces,
          ">",
          "DateDeces"
        );
        const _DateDeces = resultValidateDateDeces.fullDate || null;
        if (
          resultValidateDateDeces.error &&
          resultValidateDateDeces.error.hasError
        ) {
          const errorLabelKeys = resultValidateDateDeces.error.errorLabelKeys;
          this.hasError = true;
          this.errorDetail.decesAll = true;
          if (this.textErreur) {
            for (let i = 0; i < errorLabelKeys.length; i++) {
              this.errors.push(this.textErreur[errorLabelKeys[i]]);
            }
          }
        }

        // validate 'deces' vs 'naissance'
        // lastDate cannot be bigger than firstDate
        const firstDate = this.isniForms.isniForms.donneeIdentification.date
          .naissance;
        firstDate.fullDate = _DateNaissance;
        const lastDate = this.isniForms.isniForms.donneeIdentification.date
          .deces;
        lastDate.fullDate = _DateDeces;
        const resultCompareDates = validateDateService.compareDates(
          firstDate,
          lastDate,
          "DateDeces"
        );
        if (resultCompareDates.error && resultCompareDates.error.hasError) {
          const errorLabelKeys = resultCompareDates.error.errorLabelKeys;
          this.hasError = true;
          this.errorDetail.decesAll = true;
          if (this.textErreur) {
            for (let i = 0; i < errorLabelKeys.length; i++) {
              this.errors.push(this.textErreur[errorLabelKeys[i]]);
            }
          }
        }

        // instrument de Music
        if (
          this.isniForms.isniForms.donneeIdentification.instrumentMusic.length >
          3
        ) {
          this.errors.push(
            this.textErreur.e2PIdpubliqueDomaineactiviteListeMusiqueAc
          );
          this.hasError = true;
        }
        // lieu de naissance
        if (
          !this.isniForms.isniForms.donneeIdentification.lieu.naissance.pays
        ) {
          this.errors.push(
            this.textErreur.e2PIdpubliqueLieunaissanceListePaysErreur
          );
          this.hasError = true;
        }
        // province de naissance
        if (
          this.isniForms.isniForms.donneeIdentification.lieu.naissance.pays ==
          "CA"
        ) {
          if (
            !this.isniForms.isniForms.donneeIdentification.lieu.naissance.region
          ) {
            this.errors.push(
              this.textErreur.e2PIdpubliqueLieunaissanceListeProvinceErreur
            );
            this.hasError = true;
          }
        }
        // ville de naissance
        if (
          !this.isniForms.isniForms.donneeIdentification.lieu.naissance.ville
        ) {
          this.errors.push(
            this.textErreur.e2PIdpubliqueLieunaissanceInputVilleErreur
          );
          this.hasError = true;
        }
        // lieu de residence
        if (
          !this.isniForms.isniForms.donneeIdentification.lieu.residence.pays
        ) {
          this.errors.push(
            this.textErreur.e2PIdpubliqueLieuresidenceListePaysErreur
          );
          this.hasError = true;
        }
        // region de residence
        if (
          this.isniForms.isniForms.donneeIdentification.lieu.residence.pays ==
          "CA"
        ) {
          if (
            !this.isniForms.isniForms.donneeIdentification.lieu.residence.region
          ) {
            this.errors.push(
              this.textErreur.e2PIdpubliqueLieuresidenceListeProvinceErreur
            );
            this.hasError = true;
          }
        }
        // ville de residence
        if (
          !this.isniForms.isniForms.donneeIdentification.lieu.residence.ville
        ) {
          this.errors.push(
            this.textErreur.e2PIdpubliqueLieuresidenceInputVilleErreur
          );
          this.hasError = true;
        }
        // domaine activite
        if (!this.isniForms.isniForms.donneeIdentification.domaineActivite) {
          this.errors.push(
            this.textErreur.e2CIdpubliqueListeDomaineactiviteErreur
          );
          this.hasError = true;
        }
        // 'pseudo' and 'nom reel' empty 'nom' check
        if (
          this.isniForms.isniForms.reelNomOuPseudo.check &&
          !this.isniForms.isniForms.reelNomOuPseudo.nom
        ) {
          this.hasError = true;
          this.errors.push(
            this.isniForms.isniForms.donneeIdentification.indNomReel ===
              "pseudo"
              ? this.textErreur.e2PIdassocieInputNomLabel1Erreur
              : this.textErreur.e2PIdassocieInputNomLabel1Erreur
          );
        }
        ///*********************** Associe
        // associe nom 0
        if (
          this.isniForms.isniForms.donneeIdentification.nomAssocie
            .idNomAssocieAccept0.check
        ) {
          // Si la checkbox est egale a true
          if (
            !this.isniForms.isniForms.donneeIdentification.nomAssocie
              .idNomAssocieAccept0.associeNom
          ) {
            this.errors.push(this.textErreur.e2CIdassocienomInputIdErreur);
            this.hasError = true;
          }
          if (
            !this.isniForms.isniForms.donneeIdentification.nomAssocie
              .idNomAssocieAccept0.associeNomType
          ) {
            this.errors.push(this.textErreur.e2CIdassocienomListeTypeidErreur);
            this.hasError = true;
          }
        }
        // associe nom 1
        if (
          this.isniForms.isniForms.donneeIdentification.nomAssocie
            .idNomAssocieAccept1.check
        ) {
          // Si la checkbox est egale a true
          if (
            !this.isniForms.isniForms.donneeIdentification.nomAssocie
              .idNomAssocieAccept1.associeNom
          ) {
            this.errors.push(this.textErreur.e2CIdassocienomInputIdErreur);
            this.hasError = true;
          }
          if (
            !this.isniForms.isniForms.donneeIdentification.nomAssocie
              .idNomAssocieAccept1.associeNomType
          ) {
            this.errors.push(this.textErreur.e2CIdassocienomListeTypeidErreur);
            this.hasError = true;
          }
        }
        // associe nom 2
        if (
          this.isniForms.isniForms.donneeIdentification.nomAssocie
            .idNomAssocieAccept2.check
        ) {
          // Si la checkbox est egale a true
          if (
            !this.isniForms.isniForms.donneeIdentification.nomAssocie
              .idNomAssocieAccept2.associeNom
          ) {
            this.errors.push(this.textErreur.e2CIdassocienomInputIdErreur);
            this.hasError = true;
          }
          if (
            !this.isniForms.isniForms.donneeIdentification.nomAssocie
              .idNomAssocieAccept2.associeNomType
          ) {
            this.errors.push(this.textErreur.e2CIdassocienomListeTypeidErreur);
            this.hasError = true;
          }
        }
        ///*********************** Variante
        // variante nom 0
        if (
          this.isniForms.isniForms.donneeIdentification.variante.idVariante0
            .check
        ) {
          // Si la checkbox est egale a true
          if (
            !this.isniForms.isniForms.donneeIdentification.variante.idVariante0
              .nom
          ) {
            this.errors.push(this.textErreur.e2PVariantenomInputNomErreur);
            this.hasError = true;
          }
        }
        // variante nom 1
        if (
          this.isniForms.isniForms.donneeIdentification.variante.idVariante1
            .check
        ) {
          // Si la checkbox est egale a true
          if (
            !this.isniForms.isniForms.donneeIdentification.variante.idVariante1
              .nom
          ) {
            this.errors.push(this.textErreur.e2PVariantenomInputNomErreur);
            this.hasError = true;
          }
        }
        // variante nom 2
        if (
          this.isniForms.isniForms.donneeIdentification.variante.idVariante2
            .check
        ) {
          // Si la checkbox est egale a true
          if (
            !this.isniForms.isniForms.donneeIdentification.variante.idVariante2
              .nom
          ) {
            this.errors.push(this.textErreur.e2PVariantenomInputNomErreur);
            this.hasError = true;
          }
        }
        // variante nom 3
        if (
          this.isniForms.isniForms.donneeIdentification.variante.idVariante3
            .check
        ) {
          // Si la checkbox est egale a true
          if (
            !this.isniForms.isniForms.donneeIdentification.variante.idVariante3
              .nom
          ) {
            this.errors.push(this.textErreur.e2PVariantenomInputNomErreur);
            this.hasError = true;
          }
        }
        // variante nom 4
        if (
          this.isniForms.isniForms.donneeIdentification.variante.idVariante4
            .check
        ) {
          // Si la checkbox est egale a true
          if (
            !this.isniForms.isniForms.donneeIdentification.variante.idVariante4
              .nom
          ) {
            this.errors.push(this.textErreur.e2PVariantenomInputNomErreur);
            this.hasError = true;
          }
        }
      }
    },
    /**
     * Gestion des erreurs dans la step3
     */
    getErrorsStep3() {
      // vérification des caractères non latins
      if (!this.isLatinCode(JSON.stringify(this.isniForms.isniForms.oeuvre))) {
        this.errors.push(this.textErreur.formTexteLatinErreur);
        this.hasError = true;
      } else {
        // Si les champs est vide ou incorrect alors on affiche l'erreur

        // Oeuvre 0
        if (!this.isniForms.isniForms.oeuvre.idOeuvre0.typeOeuvre) {
          this.errors.push(this.textErreur.e3CInfosoeuvreListeTypeErreur);
          this.hasError = true;
        }
        if (!this.isniForms.isniForms.oeuvre.idOeuvre0.roleOeuvre) {
          this.errors.push(this.textErreur.e3CInfosoeuvreListeRoleErreur);
          this.hasError = true;
        }
        if (!this.isniForms.isniForms.oeuvre.idOeuvre0.thematiqueOeuvre) {
          this.errors.push(this.textErreur.e3CInfosoeuvreListeThematiqueErreur);
          this.hasError = true;
        }
        // titre oeuvre
        if (this.isniForms.isniForms.oeuvre.idOeuvre0.indOeuvreCollective) {
          // Si le bouton radio est egale a oui
          if (
            !this.isniForms.isniForms.oeuvre.idOeuvre0.titreOeuvreCollective
          ) {
            this.errors.push(
              this.textErreur.e3PInfosoeuvreInputTitreoeuvrecollaborativeErreur
            );
            this.hasError = true;
          }
        }
        // titre oeuvre periodique
        if (!this.isniForms.isniForms.oeuvre.idOeuvre0.indOeuvreCollective) {
          // Si le bouton radio est egale a non
          if (!this.isniForms.isniForms.oeuvre.idOeuvre0.titreOeuvre) {
            this.errors.push(
              this.textErreur.e3CInfosoeuvreInputTitreoeuvreErreur
            );
            this.hasError = true;
          }
        }
        //
        // Oeuvre 1
        if (this.isniForms.isniForms.oeuvre.idOeuvre1.ajoutCheck) {
          if (!this.isniForms.isniForms.oeuvre.idOeuvre1.typeOeuvre) {
            this.errors.push(this.textErreur.e3CInfosoeuvreListeTypeErreur);
            this.hasError = true;
          }
          if (!this.isniForms.isniForms.oeuvre.idOeuvre1.roleOeuvre) {
            this.errors.push(this.textErreur.e3CInfosoeuvreListeRoleErreur);
            this.hasError = true;
          }
          if (!this.isniForms.isniForms.oeuvre.idOeuvre1.thematiqueOeuvre) {
            this.errors.push(
              this.textErreur.e3PInfosoeuvreInputTitreoeuvrecollaborativeErreur
            );
            this.hasError = true;
          }
          // titre oeuvre
          if (this.isniForms.isniForms.oeuvre.idOeuvre1.indOeuvreCollective) {
            // Si le bouton radio est egale a oui
            if (
              !this.isniForms.isniForms.oeuvre.idOeuvre1.titreOeuvreCollective
            ) {
              this.errors.push(
                this.textErreur
                  .e3PInfosoeuvreInputTitreoeuvrecollaborativeErreur
              );
              this.hasError = true;
            }
          }
          // titre oeuvre periodique
          if (!this.isniForms.isniForms.oeuvre.idOeuvre1.indOeuvreCollective) {
            // Si le bouton radio est egale a non
            if (!this.isniForms.isniForms.oeuvre.idOeuvre1.titreOeuvre) {
              this.errors.push(
                this.textErreur.e3CInfosoeuvreInputTitreoeuvreErreur
              );
              this.hasError = true;
            }
          }
        }
        //
        // Oeuvre 2
        if (this.isniForms.isniForms.oeuvre.idOeuvre2.ajoutCheck) {
          if (!this.isniForms.isniForms.oeuvre.idOeuvre2.typeOeuvre) {
            this.errors.push(this.textErreur.e3CInfosoeuvreListeTypeErreur);
            this.hasError = true;
          }
          if (!this.isniForms.isniForms.oeuvre.idOeuvre2.roleOeuvre) {
            this.errors.push(this.textErreur.e3CInfosoeuvreListeRoleErreur);
            this.hasError = true;
          }
          if (!this.isniForms.isniForms.oeuvre.idOeuvre2.thematiqueOeuvre) {
            this.errors.push(
              this.textErreur.e3CInfosoeuvreListeThematiqueErreur
            );
            this.hasError = true;
          }
          // titre oeuvre
          if (this.isniForms.isniForms.oeuvre.idOeuvre2.indOeuvreCollective) {
            // Si le bouton radio est egale a oui
            if (
              !this.isniForms.isniForms.oeuvre.idOeuvre2.titreOeuvreCollective
            ) {
              this.errors.push(
                this.textErreur
                  .e3PInfosoeuvreInputTitreoeuvrecollaborativeErreur
              );
              this.hasError = true;
            }
          }
          // titre oeuvre periodique
          if (!this.isniForms.isniForms.oeuvre.idOeuvre2.indOeuvreCollective) {
            // Si le bouton radio est egale a non
            if (!this.isniForms.isniForms.oeuvre.idOeuvre2.titreOeuvre) {
              this.errors.push(
                this.textErreur.e3CInfosoeuvreInputTitreoeuvreErreur
              );
              this.hasError = true;
            }
          }
        }
        //
        // Oeuvre 3
        if (this.isniForms.isniForms.oeuvre.idOeuvre3.ajoutCheck) {
          if (!this.isniForms.isniForms.oeuvre.idOeuvre3.typeOeuvre) {
            this.errors.push(this.textErreur.e3CInfosoeuvreListeTypeErreur);
            this.hasError = true;
          }
          if (!this.isniForms.isniForms.oeuvre.idOeuvre3.roleOeuvre) {
            this.errors.push(this.textErreur.e3CInfosoeuvreListeRoleErreur);
            this.hasError = true;
          }
          if (!this.isniForms.isniForms.oeuvre.idOeuvre3.thematiqueOeuvre) {
            this.errors.push(
              this.textErreur.e3CInfosoeuvreListeThematiqueErreur
            );
            this.hasError = true;
          }
          // titre oeuvre
          if (this.isniForms.isniForms.oeuvre.idOeuvre3.indOeuvreCollective) {
            // Si le bouton radio est egale a oui
            if (
              !this.isniForms.isniForms.oeuvre.idOeuvre3.titreOeuvreCollective
            ) {
              this.errors.push(
                this.textErreur
                  .e3PInfosoeuvreInputTitreoeuvrecollaborativeErreur
              );
              this.hasError = true;
            }
          }
          // titre oeuvre periodique
          if (!this.isniForms.isniForms.oeuvre.idOeuvre3.indOeuvreCollective) {
            // Si le bouton radio est egale a non
            if (!this.isniForms.isniForms.oeuvre.idOeuvre3.titreOeuvre) {
              this.errors.push(
                this.textErreur.e3CInfosoeuvreInputTitreoeuvreErreur
              );
              this.hasError = true;
            }
          }
        }
      }
      //
    },
    /**
     * Gestion des erreurs dans la step4
     */
    getErrorsStep4() {
      this.errors = [];
      this.errorDetail = {};
      if (this.step == 4) {
        // vérification des caractères non latins
        if (
          !this.isLatinCode(JSON.stringify(this.isniForms.isniForms.relation))
        ) {
          this.errors.push(this.textErreur.formTexteLatinErreur);
          this.hasError = true;
        } else {
          // Si les champs est vide ou incorrect alors on affiche l'erreur

          // Relation 0
          if (this.isniForms.isniForms.relation.relationProCheck) {
            // Si le bouton radio est egale a oui
            this.hasError = true;
            if (!this.isniForms.isniForms.relation.idPersonne0.typeRelation) {
              this.errors.push(
                this.textErreur.e4CRelationpersonneListeTyperelationErreur
              );
              this.hasError = true;
            }
            if (!this.isniForms.isniForms.relation.idPersonne0.nom) {
              this.errors.push(
                this.textErreur.e4CRelationpersonneInputNomErreur
              );
              this.hasError = true;
            }
          }
          //
          // Relation 1
          if (this.isniForms.isniForms.relation.idPersonne1.relationProCheck) {
            // Si le bouton radio est egale a oui
            this.hasError = true;
            if (!this.isniForms.isniForms.relation.idPersonne1.typeRelation) {
              this.errors.push(
                this.textErreur.e4CRelationpersonneListeTyperelationErreur
              );
              this.hasError = true;
            }
            if (!this.isniForms.isniForms.relation.idPersonne1.nom) {
              this.errors.push(
                this.textErreur.e4CRelationpersonneInputNomErreur
              );
              this.hasError = true;
            }
          }
          //
          // Relation 2
          if (this.isniForms.isniForms.relation.idPersonne2.relationProCheck) {
            // Si le bouton radio est egale a oui
            this.hasError = true;
            if (!this.isniForms.isniForms.relation.idPersonne2.typeRelation) {
              this.errors.push(
                this.textErreur.e4CRelationpersonneListeTyperelationErreur
              );
              this.hasError = true;
            }
            if (!this.isniForms.isniForms.relation.idPersonne2.nom) {
              this.errors.push(
                this.textErreur.e4CRelationpersonneInputNomErreur
              );
              this.hasError = true;
            }
          }
          // Relation Orga 0
          if (this.isniForms.isniForms.relation.relationOrganismeCheck) {
            // Si le bouton radio est egale a non
            this.hasError = true;
            if (!this.isniForms.isniForms.relation.idOrganisme0.typeRelation) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeListeTyperelationErreur
              );
              this.hasError = true;
            }
            if (!this.isniForms.isniForms.relation.idOrganisme0.nomOrganisme) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeInputNomErreur
              );
              this.hasError = true;
            }
          }
          //
          // Relation Orga 1
          if (this.isniForms.isniForms.relation.idOrganisme1.relationProCheck) {
            // Si le bouton radio est egale a non
            this.hasError = true;
            if (!this.isniForms.isniForms.relation.idOrganisme1.typeRelation) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeListeTyperelationErreur
              );
              this.hasError = true;
            }
            if (!this.isniForms.isniForms.relation.idOrganisme1.nomOrganisme) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeInputNomErreur
              );
              this.hasError = true;
            }
          }
          // Relation Orga 2
          if (this.isniForms.isniForms.relation.idOrganisme2.relationProCheck) {
            // Si le bouton radio est egale a non
            this.hasError = true;
            if (!this.isniForms.isniForms.relation.idOrganisme2.typeRelation) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeListeTyperelationErreur
              );
              this.hasError = true;
            }
            if (!this.isniForms.isniForms.relation.idOrganisme2.nomOrganisme) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeInputNomErreur
              );
              this.hasError = true;
            }
          }
        }
      }
    },
    isLatinCode: function (str) {
      // check https://en.wikipedia.org/wiki/Latin_script_in_Unicode
      // eslint-disable-next-line
      const regexRule = /^[\u0000-\u00FF]+$/gmu;
      const isLatin = regexRule.test(str);

      return isLatin;
    },
  },
  computed: {
    /**
     ** activation du bouton suivant sur la step1, valide les consentenents et le champs email vide
     */
    activButtonStep1() {
      if (
        !this.isniForms.isniForms.consentement.admissible ||
        !this.isniForms.isniForms.consentement.information ||
        !this.isniForms.isniForms.consentement.demandeIsni ||
        !this.isniForms.isniForms.demandeur.langueFormulaire ||
        !this.isniForms.isniForms.demandeur.email
      ) {
        return true;
      } else {
        return false;
      }
    },
    /**
     ** activation du bouton suivant sur la step4
     */
    activButtonStep4() {
      if (
        !this.isniForms.isniForms.validationForm.attesteAutorise ||
        !this.isniForms.isniForms.validationForm.attesteRenseignement
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  /**
   ** mise en place du js de materialize
   */
  mounted() {
    // redirection when user reload the page
    if (!this.textFormulaire) {
      this.$router.push({ name: "Home" });
    }

    // treatment after email validation
    if (this.jumpToStep && typeof this.jumpToStep === "number") {
      this.step = this.jumpToStep;
    }
    // const formmm = this.textFormulaire;
    this.goToTopForm();
  },
  /**
   ** mise a jour du js de materialize
   */
  updated() {
    M.AutoInit();
    // si on passe a la step2 alors go to top
    if (this.step >= 2 && this.step <= 3) {
      this.goToTopForm();
    }
  },
  created() {
    // si on passe a la step2 alors go to top
    if (this.step >= 2 && this.step <= 3) {
      this.goToTopForm();
    }
  },
};
</script>
<style scoped lang="scss">
.h-btn-darken:hover {
  background-color: #0d47a1 !important;
}
.h-btn-light:hover {
  background-color: #03a9f4 !important;
}
.h-btn-amber:hover {
  background-color: #ff6f00 !important;
}
.h-btn-green:hover {
  background-color: #388e3c !important;
}
.pd-10 {
  padding: 10px;
}

.bg-grey {
  background-color: #f5f5f5;
}

.br-10 {
  border-radius: 10px;
}

.brd-1 {
  border: solid 1px grey;
}

.wrapper-btn {
  .d-flex {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
</style>
