<template>
  <div class="row isni-wrapper--step3">
    <div class="row step3--wrapper__title">
      <div class="col s12">
        <h3 class="h4-title light-blue-text text-darken-4">
          {{ dataText.e3PFormulaireTitre }}
        </h3>
      </div>
    </div>
    <div class="row z-depth-2 step3--section__content">
      <div class="col s12 mt-15">
        <!-- {{ oeuvre }} -->
        <div class="input-field col s12 m6 l6">
          <select
            v-model="isniForm.oeuvre.idOeuvre0.typeOeuvre"
            name="Tthematique"
          >
            <option value disabled selected
              >{{ dataText.eCListeTexteParDefaut }}
            </option>
            <option
              v-for="(ref, index) in dataSelect.RefTypeOeuvre"
              :value="index"
              :key="index"
              >{{ ref }}
            </option>
          </select>
          <label v-bind:class="{ error: hasError }" for="Tthematique">
            {{ dataText.e3CInfosoeuvreListeTypeLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e3CInfosoeuvreListeTypeAc">
              <icon iconText="help" />
            </Isni-Tooltip>
          </label>
        </div>
        <!-- {{ role oeuvre }} -->
        <div class="input-field col s12 m6 l6">
          <select v-model="isniForm.oeuvre.idOeuvre0.roleOeuvre" name="Orole">
            <option value disabled selected
              >{{ dataText.eCListeTexteParDefaut }}
            </option>
            <option
              v-for="(ref, index) in dataSelect.RefRoleDemandeur"
              :value="index"
              :key="index"
              >{{ ref }}
            </option>
          </select>
          <label :class="{ error: hasError }" for="Orole">
            {{ dataText.e3CInfosoeuvreListeRoleLabel }}
            <span class="red-text">*</span>
          </label>
        </div>
        <!-- {{ thematique oeuvre }} -->
        <div class="input-field col s12 m12 l12">
          <select
            v-model="isniForm.oeuvre.idOeuvre0.thematiqueOeuvre"
            name="Otheme"
          >
            <option value disabled selected
              >{{ dataText.eCListeTexteParDefaut }}
            </option>
            <option
              v-for="(ref, index) in dataSelect.RefThematique"
              :value="index"
              :key="index"
              >{{ ref }}
            </option>
          </select>
          <label for="Otheme" :class="{ error: hasError }">
            {{ dataText.e3CInfosoeuvreListeThematiqueLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e3PInfosoeuvreListeThematiqueAc">
              <icon iconText="help" />
            </Isni-Tooltip>
          </label>
        </div>
      </div>

      <div class="col s12">
        <p class="light-blue-text text-darken-4">
          {{ dataText.e3PInfosoeuvreSoustitreTexte }}
        </p>
        <p>
          <label>
            <input
              name="group0"
              type="radio"
              :value="true"
              class="with-gap"
              v-model="isniForm.oeuvre.idOeuvre0.indOeuvreCollective"
            />
            <span class="black-text fw-400">
              {{ dataText.e3PInfosoeuvreRb1Texte }}
            </span>
          </label>
        </p>
        <p>
          <label>
            <input
              name="group0"
              type="radio"
              class="with-gap"
              :value="false"
              v-model="isniForm.oeuvre.idOeuvre0.indOeuvreCollective"
            />
            <span class="black-text fw-400">{{
              dataText.e3PInfosoeuvreRb2Texte
            }}</span>
          </label>
        </p>
      </div>

      <!-- {{ si oui }} -->
      <div
        class="col s12"
        v-show="isniForm.oeuvre.idOeuvre0.indOeuvreCollective == true"
      >
        <div class="input-field col s12 m8 l8">
          <input
            id="ToeuvreCollective"
            type="text"
            class="validate"
            v-model="isniForm.oeuvre.idOeuvre0.titreOeuvreCollective"
          />
          <label
            for="ToeuvreCollective"
            v-bind:class="{
              error: hasError,
              active: !isniForm.oeuvre.idOeuvre0.titreOeuvreCollective == ''
            }"
          >
            {{ dataText.e3PInfosoeuvreInputTitreoeuvrecollaborativeLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip
              :content="dataText.e3PInfosoeuvreInputTitreoeuvrecollaborativeAc"
            >
              <icon iconText="help" />
            </Isni-Tooltip>
          </label>
        </div>
      </div>

      <!-- {{ si nom }} -->
      <div
        class="col s12"
        v-show="isniForm.oeuvre.idOeuvre0.indOeuvreCollective == false"
      >
        <div class="input-field col s12 m6 l6">
          <input
            id="ToeuvrePeriodique"
            type="text"
            class="validate"
            v-model="isniForm.oeuvre.idOeuvre0.titreOeuvre"
          />
          <label
            for="ToeuvrePeriodique"
            v-bind:class="{
              error: hasError,
              active: !isniForm.oeuvre.idOeuvre0.titreOeuvre == ''
            }"
          >
            {{ dataText.e3CInfosoeuvreInputTitreoeuvreLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e3CInfosoeuvreInputTitreoeuvreAc">
              <icon iconText="help" />
            </Isni-Tooltip>
          </label>
        </div>
        <div class="input-field col s12 m6 l6">
          <input
            id="StitreOeuvre"
            type="text"
            class="validate"
            v-model="isniForm.oeuvre.idOeuvre0.sousTitreOeuvre"
          />
          <label
            for="StitreOeuvre"
            :class="{
              active: !isniForm.oeuvre.idOeuvre0.sousTitreOeuvre == ''
            }"
          >
            {{ dataText.e3CInfosoeuvreInputSoustitreoeuvreLabel }}
            <Isni-Tooltip
              :content="dataText.e3CInfosoeuvreInputSoustitreoeuvreAc"
            >
              <icon iconText="help" />
            </Isni-Tooltip>
          </label>
        </div>
      </div>

      <div class="col s12">
        <p>
          <label>
            <input
              type="checkbox"
              :value="true"
              v-model="isniForm.oeuvre.idOeuvre1.ajoutCheck"
            />
            <template v-if="!isniForm.oeuvre.idOeuvre1.ajoutCheck">
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbAjoutoccurrence1Texte
              }}</span>
            </template>
            <template v-else>
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbRetraitoccurrence1Texte
              }}</span>
            </template>
          </label>
        </p>
      </div>

      <app-section-oeuvre-1
        :isniForm="isniForm"
        :hasError="hasError"
        :dataText="dataText"
        :dataSelect="dataSelect"
      >
      </app-section-oeuvre-1>

      <div class="col s12">
        <p>
          <label>
            <input
              type="checkbox"
              :value="true"
              v-model="isniForm.oeuvre.idOeuvre2.ajoutCheck"
            />
            <template v-if="!isniForm.oeuvre.idOeuvre2.ajoutCheck">
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbAjoutoccurrence2Texte
              }}</span>
            </template>
            <template v-else>
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbRetraitoccurrence2Texte
              }}</span>
            </template>
          </label>
        </p>
      </div>

      <app-section-oeuvre-2
        :isniForm="isniForm"
        :hasError="hasError"
        :dataText="dataText"
        :dataSelect="dataSelect"
      >
      </app-section-oeuvre-2>

      <div class="col s12">
        <p>
          <label>
            <input
              type="checkbox"
              :value="true"
              v-model="isniForm.oeuvre.idOeuvre3.ajoutCheck"
            />
            <template v-if="!isniForm.oeuvre.idOeuvre3.ajoutCheck">
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbAjoutoccurrence3Texte
              }}</span>
            </template>
            <template v-else>
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbRetraitoccurrence3Texte
              }}</span>
            </template>
          </label>
        </p>
      </div>

      <app-section-oeuvre-3
        :isniForm="isniForm"
        :hasError="hasError"
        :dataText="dataText"
        :dataSelect="dataSelect"
      >
      </app-section-oeuvre-3>

      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import Icon from "./../elements/Icon";
import IsniTooltip from "./../elements/IsniTooltip";
import Oeuvre1 from "./../sections/step3/oeuvre1";
import Oeuvre2 from "./../sections/step3/oeuvre2";
import Oeuvre3 from "./../sections/step3/oeuvre3";

export default {
  name: "Step3",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    "data-position": {
      type: String
    },
    "data-tooltip": {
      type: String
    },
    hasError: Boolean
  },
  data: function() {
    return {
      titreOeuvre: String
    };
  },
  components: {
    Icon,
    IsniTooltip,
    "app-section-oeuvre-1": Oeuvre1,
    "app-section-oeuvre-2": Oeuvre2,
    "app-section-oeuvre-3": Oeuvre3
  }
};
</script>
<style lang="scss" scoped>
.step3--section__list {
  .h4-title{
        font-size: 2.28rem;
        line-height: 110%;
        margin: 1.52rem 0 .912rem 0;
    }
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      margin: 0 20px;
    }
  }
}
</style>
