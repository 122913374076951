<!--
Composant Step


-->
<template>
  <div>
    <div class="row z-depth-2 wrapper-consent border-tour-blue">
      <div class="col s12 l12">
        <p>
          <label>
            <input
              type="checkbox"
              class="filled-in"
              id="chk1-consent"
              v-model="isniForm.consentement.admissible"
            />
            <span v-html="dataText.e1CConsentementCbTexte"></span>
          </label>
        </p>
        <p>
          <label>
            <input
              type="checkbox"
              class="filled-in"
              id="chk2-consent"
              v-model="isniForm.consentement.information"
            />
            <span v-html="dataText.e1PVerificationCbTexte"></span>
          </label>
        </p>
        <p>
          <label>
            <input
              type="checkbox"
              class="filled-in"
              id="chk3-consent"
              v-model="isniForm.consentement.demandeIsni"
            />
            <span v-html="dataText.e1PAdmissibiliteCbTexte"> </span>
          </label>
        </p>
      </div>
    </div>
    <div class="row z-depth-1 pg-10">
      <!--            TODO: en attendre la version anglaise-->
      <div class="input-field col s12 m6 l6">
        <select
          name="langue"
          title="langue"
          required
          v-model="isniForm.demandeur.langueFormulaire"
        >
          <option value disabled selected>
            {{ dataText.e1CListeLangueErreur }}
          </option>
          <option
            v-for="(ref, index) in dataSelect.RefLangueCommunication"
            :value="index"
            :key="index"
          >
            {{ ref }}
          </option>
        </select>
        <label for="langCommunicat" :class="{ error: hasError }">
          {{ dataText.e1CListeLangueLabel }}
          <span class="red-text">*</span>
        </label>
      </div>
      <div class="input-field col s12 m6 l6">
        <input
          id="email"
          type="email"
          class="validate"
          name="email"
          title="Adresse courriel"
          required
          v-model="isniForm.demandeur.email"
        />
        <label
          for="email"
          :class="{
            error: hasError,
            active: !isniForm.demandeur.email == '',
          }"
        >
          {{ dataText.e1CInputCourrielLabel }}
          <span class="red-text">*</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step-1",
  props: {
    isniForm: {
      type: Object,
      required: true,
    },
    dataText: {
      type: Object,
      required: true,
    },
    dataSelect: {
      type: Object,
      required: true,
    },
    hasError: Boolean,
  },
  created: function () {
    // TODO: temporaire : en attendre la version anglaise
    // this.isniForm.demandeur.langueFormulaire = "fr";
  },
};
</script>
<style lang="scss">
.wrapper-consent {
  [type="checkbox"] + span:not(.lever) {
    color: black;
    font-weight: 600;
  }
  span.rules {
    /*font-style: italic;*/
    /*text-decoration: underline;*/
    /*color: #01579b;*/
  }
  span.super {
    vertical-align: super;
    font-size: 10px;
  }
}
</style>
