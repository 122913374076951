<!--
Composant


-->
<template>
    <div>
        <div class="row step2--wrapper__title">
            <div class="col s12">
                <h3 class="h4-title light-blue-text text-darken-4">
                    {{ dataText.e2PFormulaireTitre }}
                </h3>
            </div>
        </div>
        <!-- {{ Nom, prenom, Genre, Nationalite, Site internet ou pseudo }} -->
        <div class="row z-depth-1">
            <!-- {{choix Nom & Pseudo}} -->
            <div class="col s12">
                <div class="bt row"></div>
                <p class="light-blue-text text-darken-4">
                    {{ dataText.e2PIdpubliqueTypeSoustitreTexte }}
                    <Isni-Tooltip :content="dataText.e2PIdpubliqueTypeSoustitreAc">
                        <Icon iconText="help"/>
                    </Isni-Tooltip>
                </p>
                <div class="col s6">
                    <div class="col s12">
                        <label>
                            <input
                                    name="nomReel"
                                    type="radio"
                                    value="nomreel"
                                    checked
                                    v-model="isniForm.donneeIdentification.indNomReel"
                                    class="with-gap"
                            />
                            <span class="black-text">{{ dataText.e2PIdpubliqueTypeRb1Texte }}</span>
                        </label>
                    </div>
                    <div class="col s12">
                        <label>
                            <input
                                    name="nomReel"
                                    type="radio"
                                    value="pseudo"
                                    for="pseudo"
                                    v-model="isniForm.donneeIdentification.indNomReel"
                                    class="with-gap"
                            />
                            <span class="black-text">{{
                dataText.e2PIdpubliqueTypeRb2Texte
              }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <!-- {{ nom }} -->
            <div class="input-field col s12 m4 l4">
                <input
                        id="nom"
                        type="text"
                        class="validate"
                        v-model="isniForm.donneeIdentification.diPersoNom"
                />
                <label
                        for="nom"
                        required
                        class="dynamicTooltip"
                        v-bind:class="{
            error: hasError,
            active: !isniForm.donneeIdentification.diPersoNom == ''
          }"
                >
                    {{ dataText.e2PIdpubliqueTypeNomreelInputNomLabel }}
                    <span class="red-text">*</span>
                    <span v-show="isniForm.donneeIdentification.indNomReel === 'pseudo'">
                        <Isni-Tooltip :content="dataText.e2PIdpubliqueTypePseudoInputNomAc">
                            <Icon iconText="help"/>
                        </Isni-Tooltip>
                    </span>
                </label>
            </div>
            <!-- {{ prenom }} -->
            <div class="input-field col s12 m4 l4">
                <input
                        id="prenom"
                        type="text"
                        class="validate"
                        v-model="isniForm.donneeIdentification.diPersoPrenom"
                />
                <label
                        for="prenom"
                        v-bind:class="{
            active: !isniForm.donneeIdentification.diPersoPrenom == ''
          }"
                >{{ dataText.e2PIdpubliqueTypeInputPrenomLabel }}</label
                >
            </div>
            <!-- {{ Genre }} -->
            <div class="input-field col s12 m4 l4">
                <select required v-model="isniForm.donneeIdentification.diPersoGenre" name="genre" id="RefGenre">
                    <option value disabled selected
                    >{{ dataText.eCListeTexteParDefaut }}
                    </option>
                    <option
                            v-for="(ref, index) in dataSelect.RefGenre"
                            :value="index"
                            :key="index"
                    >{{ ref }}
                    </option>
                </select>
                <label v-bind:class="{ error: hasError }">
                    {{ dataText.e2PIdpubliqueTypeListeGenreLabel }}
                    <span class="red-text">*</span>
                </label>
            </div>
            <!-- {{ Nationalite }} -->
            <div class="input-field col s12 m6 l6 di-perso-nationalite-container">
                <select
                        multiple
                        required
                        v-model="isniForm.donneeIdentification.diPersoNationalite"
                        @change="verifyLimitDiPersoNationalite"
                        name="nationalite"
                        id="diPersoNationalite"
                >
                    <option value disabled selected
                    >{{ dataText.e2PIdpubliqueTypeListeNationaliteTexteParDefaut }}
                    </option>
                    <option
                            v-for="(ref, index) in dataSelect.RefTypeNationalite"
                            :value="index"
                            :key="index"
                    >{{ ref }}
                    </option>
                </select>
                <label :class="{ error: hasError }">
                    {{ dataText.e2PIdpubliqueTypeListeNationaliteLabel }}
                    <span class="red-text">*</span>
                    <Isni-Tooltip :content="dataText.e2PIdpubliqueTypeListeNationaliteAc">
                        <Icon iconText="help"/>
                    </Isni-Tooltip>
                </label>
            </div>
            <!-- {{ Site internet }} -->
            <div class="input-field col s12 m6 l6">
                <input
                        id="SiteInternet"
                        type="text"
                        class="validate"
                        v-model="isniForm.donneeIdentification.siteInternet"
                />
                <label
                        for="SiteInternet"
                        v-bind:class="{
            active: !isniForm.donneeIdentification.siteInternet == ''
          }"
                >
                    {{ dataText.e2CIdpubliqueTypeInputSiteLabel }}
                    <Isni-Tooltip :content="dataText.e2PIdpubliqueTypeInputSiteAc">
                        <Icon iconText="help"/>
                    </Isni-Tooltip>
                </label>
            </div>
            <!-- </div> -->

            <!-- {{ Nom, prenom, Genre, Nationalite, Site internet ou pseudo fin}} -->

            <app-date
                    :isniForm="isniForm"
                    :dataText="dataText"
                    :dataSelect="dataSelect"
                    :hasError="hasError"
                    :errorDetail="errorDetail"
            ></app-date>

            <!-- {{ Lieu de naissance }}  -->
            <div class="col s12">
                <!-- {{ Lieu de naissance - pays }} -->
                <p class="light-blue-text text-darken-4">
                    {{ dataText.e2PIdpubliqueLieunaissanceSoustitreTexte }}
                    <Isni-Tooltip :content="dataText.e2PIdpubliqueLieunaissanceSoustitreAc">
                        <Icon iconText="help"/>
                    </Isni-Tooltip>
                </p>
                <div class="input-field col s12 m6 l4">
                    
                    <select
                            required
                            @change="onChangeCountryBirth($event)"
                            v-model="isniForm.donneeIdentification.lieu.naissance.pays"
                            name="NPays"
                            id="npays"
                    >
                        <option value="" disabled selected
                        >{{ dataText.eCListeTexteParDefaut }}
                        </option>
                        <option
                                v-for="(ref, index) in dataSelect.RefPays"
                                :value="index"
                                :key="index"
                        >{{ ref }}
                        </option>
                    </select>
                    <label :class="[{ error: hasError }]">
                        {{ dataText.e2PIdpubliqueLieunaissanceListePaysLabel }}
                        <span class="red-text">*</span>
                    </label>
                </div>

                <!-- {{ Lieu de naissance - Canada }} -->
                <div v-show="canadaB">
                    <!-- {{ Lieu de naissance - regions du Canada }} -->
                    <div class="input-field col s12 m6 l4">
                        <select
                                required
                                v-model="isniForm.donneeIdentification.lieu.naissance.region"
                                @change="onChangeRegion('naissance')"
                                name="Nregion"
                                id="nregion"
                        >
                            <option value disabled selected
                            >{{ dataText.eCListeTexteParDefaut }}
                            </option>
                            <option
                                    v-for="(ref, index) in dataSelect.RefRegion"
                                    :value="index"
                                    :key="index"
                                    id="RefRegion"
                            >{{ ref }}
                            </option>
                        </select>
                        <label v-bind:class="{ error: hasError }">
                            {{ dataText.e2PIdpubliqueLieunaissanceListeProvinceLabel }}
                            <span class="red-text">*</span>
                        </label>
                    </div>

                    <!-- {{ Lieu de naissance - villes region Quebec }} -->
                    <div
                            class="input-field col s12 m12 l4"
                            v-show="isniForm.donneeIdentification.lieu.naissance.region === 'QC'"
                            @change="validateCityQuebec('naissance')"
                    >
                        <select
                                id="Vnaissance"
                                required
                                v-model="isniForm.donneeIdentification.lieu.naissance.ville"
                                name="naissanceVille"
                        >
                            <option value disabled selected>
                                {{ dataText.eCListeTexteParDefaut }}
                            </option>
                            <option
                                    v-for="(ref, index) in dataSelect.RefVille"
                                    :value="index"
                                    :key="index"
                            >{{ ref }}
                            </option>
                        </select>
                        <label
                                for="Vnaissance"
                                v-bind:class="{
                                    error: hasError,
                                    // active: !isniForm.donneeIdentification.lieu.naissance.ville == ''
                                }"
                        >
                            {{ dataText.e2PIdpubliqueLieunaissanceInputVilleLabel }}
                            <span class="red-text">*</span>
                        </label>
                    </div>

                    <!-- {{ Lieu de naissance - villes region autres que Quebec }} -->
                    <div
                            class="input-field col s12 m12 l4"
                            v-show="isniForm.donneeIdentification.lieu.naissance.region != 'QC'"
                    >
                    <label class="active" for="Vnaissance" v-bind:class="{ error: hasError }">
                            {{ dataText.e2PIdpubliqueLieunaissanceInputVilleLabel }}
                            <span class="red-text">*</span>
                    </label>
                        <app-list-cities
                                        inputId="Vnaissance"
                                        inputClass="validate"
                                        lieu="naissance"
                                        :country-model="isniForm.donneeIdentification.lieu.naissance.pays"
                                        :region-model="isniForm.donneeIdentification.lieu.naissance.region"
                                        :city-model="isniForm.donneeIdentification.lieu.naissance.ville"
                                        placeholder=""
                                        :first-item-text="dataText.eCListeTexteParDefaut"
                                        v-on:selected="validateCitySelection"
                        ></app-list-cities>
                        
                    </div>
                </div>

                <!-- {{ Lieu de naissance - villes dans pays autres que Canada }} -->
                <div v-show="!canadaB">
                    <div class="input-field col s12" :class="{m6 : isniForm.donneeIdentification.lieu.naissance.region != 'QC', l8 : isniForm.donneeIdentification.lieu.naissance.region != 'QC'}">
                        <label class="active" for="Vnaissance" v-bind:class="{ error: hasError }">
                            {{ dataText.e2PIdpubliqueLieunaissanceInputVilleLabel }}
                            <span class="red-text">*</span>
                        </label>
                        <app-list-cities
                                        inputId="Vnaissance"
                                        inputClass="validate"
                                        lieu="naissance"
                                        :country-model="isniForm.donneeIdentification.lieu.naissance.pays"
                                        region-model=""
                                        :city-model="isniForm.donneeIdentification.lieu.naissance.ville"
                                        placeholder=""
                                        :first-item-text="dataText.eCListeTexteParDefaut"
                                        v-on:selected="validateCitySelection"
                        ></app-list-cities>
                        
                    </div>
                </div>
            </div>
            <!-- {{ end Lieu de naissance }} -->

            <!-- {{ Lieu de residence }} -->
            <div class="col s12">
                <!-- {{ Lieu de residence - pays }} -->
                <p class="light-blue-text text-darken-4">
                    {{ dataText.e2PIdpubliqueLieuresidenceSoustitreTexte }}
                </p>
                <div class="input-field col s12 m6 l4">
                    <select
                            required
                            @change="onChangeCountryResidence($event)"
                            v-model="isniForm.donneeIdentification.lieu.residence.pays"
                            name="Rpays"
                            id="rpays"
                    >
                        <option value disabled selected
                        >{{ dataText.eCListeTexteParDefaut }}
                        </option>
                        <option
                                v-for="(ref, index) in dataSelect.RefPays"
                                :value="index"
                                :key="index"
                        >{{ ref }}
                        </option>
                    </select>
                    <label :class="{ error: hasError }" for="Rpays">
                        {{ dataText.e2PIdpubliqueLieuresidenceListePaysLabel }}
                        <span class="red-text">*</span>
                    </label>
                </div>

                <!-- {{ Lieu de residence - Canada }} -->
                <div v-show="canadaR">
                    <!-- {{ Lieu de residence - regions du Canada }} -->
                    <div class="input-field col s12 m6 l4">
                        <select
                                required
                                v-model="isniForm.donneeIdentification.lieu.residence.region"
                                @change="onChangeRegion('residence')"
                                name="Rregion"
                                id="rregion"
                        >
                            <option value disabled selected>
                                {{ dataText.eCListeTexteParDefaut }}
                            </option>
                            <option
                                    v-for="(ref, index) in dataSelect.RefRegion"
                                    :value="index"
                                    :key="index"
                            >{{ ref }}
                            </option>
                        </select>
                        <label v-bind:class="{ error: hasError }">
                            {{ dataText.e2PIdpubliqueLieuresidenceListeProvinceLabel }}
                            <span class="red-text">*</span>
                        </label>
                    </div>

                    <!-- {{ Lieu de residence - villes region Quebec }} -->
                    <div
                            class="input-field col s12 m12 l4"
                            v-show="isniForm.donneeIdentification.lieu.residence.region === 'QC'"
                            @change="validateCityQuebec('residence')"
                    >
                        <select
                                id="Vresidence"
                                required
                                v-model="isniForm.donneeIdentification.lieu.residence.ville"
                                name="residenceVille"
                        >
                            <option value disabled selected>
                                {{ dataText.eCListeTexteParDefaut }}
                            </option>
                            <option
                                    v-for="(ref, index) in dataSelect.RefVille"
                                    :value="index"
                                    :key="index"
                            >{{ ref }}
                            </option>
                        </select>
                        <label
                                for="Vresidence"
                                v-bind:class="{
                                    error: hasError,
                                    // active: !isniForm.donneeIdentification.lieu.residence.ville == ''
                                }"
                        >
                            {{ dataText.e2PIdpubliqueLieuresidenceInputVilleLabel }}
                            <span class="red-text">*</span>
                        </label>
                    </div>

                    <!-- {{ Lieu de residence - villes region autres que Quebec }} -->
                    <div
                            class="input-field col s12 m12 l4"
                            v-show="isniForm.donneeIdentification.lieu.residence.region != 'QC'"
                    >
                        <app-list-cities
                                        inputId="Vresidence"
                                        inputClass="validate"
                                        lieu="residence"
                                        :country-model="isniForm.donneeIdentification.lieu.residence.pays"
                                        :region-model="isniForm.donneeIdentification.lieu.residence.region"
                                        :city-model="isniForm.donneeIdentification.lieu.residence.ville"
                                        placeholder=""
                                        :first-item-text="dataText.eCListeTexteParDefaut"
                                        v-on:selected="validateCitySelection"
                        ></app-list-cities>
                        <label class="active" for="Vresidence" v-bind:class="{ error: hasError }">
                            {{ dataText.e2PIdpubliqueLieuresidenceInputVilleLabel }}
                            <span class="red-text">*</span>
                        </label>
                    </div>
                </div>

                <!-- {{ Lieu de residence - villes dans pays autres que Canada }} -->
                <div v-show="!canadaR">
                    <div class="input-field col s12" :class="{m6 : isniForm.donneeIdentification.lieu.residence.region != 'QC', l8 : isniForm.donneeIdentification.lieu.residence.region != 'QC'}">
                        <app-list-cities
                                        inputId="Vresidence"
                                        inputClass="validate"
                                        lieu="residence"
                                        :country-model="isniForm.donneeIdentification.lieu.residence.pays"
                                        region-model=""
                                        :city-model="isniForm.donneeIdentification.lieu.residence.ville"
                                        placeholder=""
                                        :first-item-text="dataText.eCListeTexteParDefaut"
                                        v-on:selected="validateCitySelection"
                        ></app-list-cities>
                        <label class="active" for="Vresidence" v-bind:class="{ error: hasError }">
                            {{ dataText.e2PIdpubliqueLieuresidenceInputVilleLabel }}
                            <span class="red-text">*</span>
                        </label>
                    </div>
                </div>
            </div>
            <!-- {{ end Lieu de residence }} -->

            <!-- {{ domaine activite }} -->
            <div class="col s12">
                <p class="light-blue-text text-darken-4">
                    {{ dataText.e2CIdpubliqueDomaineactiviteSoustitreTexte }}
                    <Isni-Tooltip :content="dataText.e2CIdpubliqueDomaineactiviteSoustitreAc">
                        <Icon iconText="help"/>
                    </Isni-Tooltip>
                </p>
                <div class="input-field col s12 m12 l6">
                    <select
                            required
                            v-model="isniForm.donneeIdentification.domaineActivite"
                            name="Dactivite"
                            id="dactivite"
                    >
                        <option value disabled selected
                        >{{ dataText.eCListeTexteParDefaut }}
                        </option>

                        <option
                                v-for="(ref, index) in dataSelect.RefDomaineActivite"
                                :value="index"
                                :key="index"
                        >{{ ref }}
                        </option>
                    </select>
                    <label :class="{ error: hasError }" for="Dactivite">
                        {{ dataText.e2CIdpubliqueListeDomaineactiviteLabel }}
                        <span class="red-text">*</span>
                    </label>
                </div>

                <!-- {{Instruments de musique}} -->
                    <div class="input-field col s12 imusic-container" v-show="isniForm.donneeIdentification.domaineActivite == '_4'">
                        <select
                                multiple
                                v-model="isniForm.donneeIdentification.instrumentMusic"
                                name="Imusic"
                                id="imusic"
                                @change="verifyLimitImusic"
                        >
                            <option value disabled
                            >{{ dataText.eCListeTexteParDefaut }}
                            </option>
                            <option
                                    v-for="(ref, index) in dataSelect.RefInstrumentsExecution"
                                    :value="index"
                                    :key="index"
                            >{{ ref }}
                            </option>
                        </select>
                        <label for="Imusic" :class="{ error: hasError }" class="pa-instrument">
                            {{ dataText.e2PIdpubliqueDomaineactiviteListeMusiqueLabel }}
                            <Isni-Tooltip :content="dataText.e2PIdpubliqueDomaineactiviteListeMusiqueAc">
                                <Icon iconText="help"/>
                            </Isni-Tooltip>
                        </label>
                    </div>
                <!-- {{Instruments de musique}} -->
            </div>
            <!-- {{ domaine activite }} -->
        </div>

        <!-- {{Nom reel ou pseudo debut}} -->
        <!-- {{TODO: a voir soucis de champs}} -->
        <div class="row z-depth-1 pb-15">
            <div class="col l12">
                <div class="bt row"></div>

                <!-- {{Identifiant(s) associé(s) au nom}} -->
                <div class="col s12">
                  <p class="light-blue-text text-darken-4">
                    <!-- {{ text checkbox if 'nom nomreel' }} -->
                    <span v-if="isniForm.donneeIdentification.indNomReel == 'nomreel'">
                      {{ dataText.e2PIdassocieSoustitreTexte1 }}
                    </span>
                    <!-- {{ text checkbox if 'pseudo' }} -->
                    <span v-if="isniForm.donneeIdentification.indNomReel == 'pseudo'">
                      {{ dataText.e2PIdassocieSoustitreTexte2 }}
                    </span>
                    <Isni-Tooltip :content="dataText.e2PIdassocieSoustitreAc">
                      <Icon iconText="help"/>
                    </Isni-Tooltip>
                  </p>
                  <!-- {{ content checkboxes }} -->
                  <p>
                    <label>
                      <input
                          ref="reelNomOuPseudo-false"
                          name="groupPseudo"
                          type="radio"
                          class="with-gap"
                          :value="false"
                          v-model="isniForm.reelNomOuPseudo.check"
                      />
                      <span class="black-text fw-400">{{ dataText.formRbNon }}</span>
                    </label>
                  </p>
                  <p>
                    <label>
                      <input
                          ref="reelNomOuPseudo-true"
                          name="groupPseudo"
                          type="radio"
                          class="with-gap"
                          :value="true"
                          v-model="isniForm.reelNomOuPseudo.check"
                      />
                      <span class="black-text fw-400">{{ dataText.formRbOui }}</span>
                    </label>
                  </p>
                  <div class="col s12" v-show="isniForm.reelNomOuPseudo.check == true">
                    <div class="input-field col s12 m6 l6">
                      <input
                          id="rnpNom"
                          type="text"
                          class="validate"
                          v-model="isniForm.reelNomOuPseudo.nom"
                      />
                      <label
                          for="rnpNom"
                          :class="{ error: hasError, active: !isniForm.reelNomOuPseudo.nom == '' }"
                      >
                        <!-- {{ label nom if 'nom nomreel' }} -->
                        <span v-if="isniForm.donneeIdentification.indNomReel == 'nomreel'">
                          {{ dataText.e2PIdassocieInputNomLabel1 }}
                        </span>
                        <!-- {{ label nom if 'pseudo' }} -->
                        <span v-if="isniForm.donneeIdentification.indNomReel == 'pseudo'">
                          {{ dataText.e2PIdassocieInputNomLabel2 }}
                        </span>
                        <span class="red-text">*</span>
                        <Isni-Tooltip v-if="isniForm.donneeIdentification.indNomReel == 'nomreel'"
                                      :content="dataText.e2PIdassocieInputNomLabel1Ac"
                        >
                          <Icon iconText="help"/>
                        </Isni-Tooltip>
                      </label>
                    </div>
                    <div class="input-field col s12 m6 l6">
                      <input
                          id="rnpPrenom"
                          type="text"
                          class="validate"
                          v-model="isniForm.reelNomOuPseudo.prenom"
                      />
                      <label
                          for="rnpPrenom"
                          :class="{ active: !isniForm.reelNomOuPseudo.prenom == '' }"
                      >
                        <!-- {{ label nom if 'nom nomreel' }} -->
                        <span v-if="isniForm.donneeIdentification.indNomReel == 'nomreel'">
                          {{ dataText.e2PIdpubliqueTypeInputPrenomLabel }}
                        </span>
                        <!-- {{ label nom if 'pseudo' }} -->
                        <span v-if="isniForm.donneeIdentification.indNomReel == 'pseudo'">
                          <!-- {{ TODO: to check. Key name seems to be incorrect  }} -->
                          {{ dataText.e2PIdassocieInputPrenomLabel2 }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col s12">
                    <p class="light-blue-text text-darken-4">
                        {{ dataText.e2PIdassocienomSoustitreTexte }}
                        <Isni-Tooltip :content="dataText.e2CIdassocienomSoustitreAc">
                            <Icon iconText="help"/>
                        </Isni-Tooltip>
                    </p>
                    <p>
                        <label>
                            <input
                                    name="group0"
                                    type="radio"
                                    class="with-gap"
                                    :value="false"
                                    v-model="
                  isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0
                    .check
                "
                            />
                            <span class="black-text fw-400">{{ dataText.formRbNon }}</span>
                        </label>
                    </p>
                    <p>
                        <label>
                            <input
                                    name="group0"
                                    type="radio"
                                    class="with-gap"
                                    :value="true"
                                    v-model="
                  isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0
                    .check
                "
                            />
                            <span class="black-text fw-400">{{ dataText.formRbOui }}</span>
                        </label>
                    </p>
                </div>

                <!-- {{ identifiant }} -->
                <div
                        class="col s12"
                        v-show="
            isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0.check
          "
                >
                    <div class="input-field col s12 m6 l6">
                        <input
                                id="Inom"
                                type="text"
                                class="validate"
                                v-model="
                isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0
                  .associeNom
              "
                        />
                        <label
                                for="Inom"
                                :class="{
                error: hasError,
                active:
                  !isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0
                    .associeNom == ''
              }"
                        >{{ dataText.e2CIdassocienomInputIdLabel }}
                            <span class="red-text">*</span></label
                        >
                    </div>

                    <div class="input-field col s12 m6 l6">
                        <select
                                id="Itype"
                                name="idNomAssocieAccept0"
                                v-model="
                isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0
                  .associeNomType
              "
                        >
                            <option value disabled selected
                            >{{ dataText.eCListeTexteParDefaut }}
                            </option>
                            <option
                                    v-for="(ref, index) in dataSelect.RefTypeAutreIdentifiant"
                                    :value="index"
                                    :key="index"
                            >{{ ref }}
                            </option>
                        </select>
                        <label :class="{ error: hasError }">
                            {{ dataText.e2CIdassocienomListeTypeidLabel }}
                            <span class="red-text">*</span>
                        </label>
                    </div>

                    <div class="col s12">
                        <p>
                            <label>
                                <input
                                        type="checkbox"
                                        :value="true"
                                        v-model="
                    isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept1
                      .check
                  "
                                />
                                <template
                                        v-if="
                    !isniForm.donneeIdentification.nomAssocie
                      .idNomAssocieAccept1.check
                  "
                                >
                  <span class="black-text fw-400">{{
                    dataText.e2CIdassocienomCbAjoutoccurrenceTexte
                  }}</span>
                                </template>
                                <template v-else>
                  <span class="black-text fw-400">{{
                    dataText.e2CIdassocienomCbRetraitoccurrenceTexte
                  }}</span>
                                </template>
                            </label>
                        </p>
                    </div>

                    <app-section-id-name-1
                            :isniForm="isniForm"
                            :hasError="hasError"
                            v-show="
              isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept1.check
            "
                            :dataText="dataText"
                            :dataSelect="dataSelect"
                    >
                    </app-section-id-name-1>

                    <div class="col s12">
                        <p>
                            <label>
                                <input
                                        type="checkbox"
                                        :value="true"
                                        v-model="
                    isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept2
                      .check
                  "
                                />
                                <template
                                        v-if="
                    !isniForm.donneeIdentification.nomAssocie
                      .idNomAssocieAccept2.check
                  "
                                >
                  <span class="black-text fw-400">{{
                    dataText.e2CIdassocienomCbAjoutoccurrence2Texte
                  }}</span>
                                </template>
                                <template v-else>
                  <span class="black-text fw-400">{{
                    dataText.e2CIdassocienomCbRetraitoccurrence2Texte
                  }}</span>
                                </template>
                            </label>
                        </p>
                    </div>
                    <app-section-id-name-2
                            :isniForm="isniForm"
                            :hasError="hasError"
                            v-show="
              isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept2.check
            "
                            :dataText="dataText"
                            :dataSelect="dataSelect"
                    >
                    </app-section-id-name-2>
                </div>

                <!-- {{Variante au nom}} -->

                <div class="col s12">
                    <p class="light-blue-text text-darken-4">
                        {{ dataText.e2PVariantenomSoustitreTexte }}
                        <Isni-Tooltip :content="dataText.e2CVariantenomSoustitreAc">
                            <Icon iconText="help"/>
                        </Isni-Tooltip>
                    </p>
                    <p>
                        <label>
                            <input
                                    name="group2"
                                    type="radio"
                                    class="with-gap"
                                    :value="false"
                                    v-model="
                  isniForm.donneeIdentification.variante.idVariante0.check
                "
                            />
                            <span class="black-text fw-400">{{ dataText.formRbNon }}</span>
                        </label>
                    </p>
                    <p>
                        <label>
                            <input
                                    name="group2"
                                    type="radio"
                                    class="with-gap"
                                    :value="true"
                                    v-model="
                  isniForm.donneeIdentification.variante.idVariante0.check
                "
                            />
                            <span class="black-text fw-400">{{ dataText.formRbOui }}</span>
                        </label>
                    </p>

                    <div
                            class="col s12"
                            v-if="
              isniForm.donneeIdentification.variante.idVariante0.check == true
            "
                    >
                        <div class="input-field col s12 m6 l6">
                            <input
                                    id="Nvariante"
                                    type="text"
                                    class="validate"
                                    v-model="isniForm.donneeIdentification.variante.idVariante0.nom"
                            />
                            <label
                                    for="Nvariante"
                                    v-bind:class="{
                  error: hasError,
                  active:
                    !isniForm.donneeIdentification.variante.idVariante0.nom ==
                    ''
                }"
                            >
                                {{ dataText.e2PVariantenomInputNomLabel }}
                                <span class="red-text">*</span>
                            </label>
                        </div>
                        <div class="input-field col s12 m6 l6">
                            <input
                                    id="Pvariante"
                                    type="text"
                                    class="validate"
                                    v-model="
                  isniForm.donneeIdentification.variante.idVariante0.prenom
                "
                            />
                            <label
                                    for="Pvariante"
                                    :class="{
                  active:
                    !isniForm.donneeIdentification.variante.idVariante0
                      .prenom == ''
                }"
                            >{{ dataText.e2PVariantenomInputPrenomLabel }}</label
                            >
                        </div>

                        <div class="col s12">
                            <p>
                                <label>
                                    <input
                                            type="checkbox"
                                            :value="true"
                                            v-model="
                      isniForm.donneeIdentification.variante.idVariante1.check
                    "
                                    />
                                    <template
                                            v-if="
                      !isniForm.donneeIdentification.variante.idVariante1.check
                    "
                                    >
                    <span class="black-text fw-400">{{
                      dataText.e2CVariantenomCbAjoutoccurrence1Texte
                    }}</span>
                                    </template>
                                    <template v-else>
                    <span class="black-text fw-400">{{
                      dataText.e2CVariantenomCbRetraitoccurrence1Texte
                    }}</span>
                                    </template>
                                </label>
                            </p>
                        </div>

                        <app-section-variante-nom-1
                                :isniForm="isniForm"
                                :hasError="hasError"
                                v-show="isniForm.donneeIdentification.variante.idVariante1.check"
                                :dataText="dataText"
                        >
                        </app-section-variante-nom-1>

                        <div class="col s12">
                            <p>
                                <label>
                                    <input
                                            type="checkbox"
                                            :value="true"
                                            v-model="
                      isniForm.donneeIdentification.variante.idVariante2.check
                    "
                                    />
                                    <template
                                            v-if="
                      !isniForm.donneeIdentification.variante.idVariante2.check
                    "
                                    >
                    <span class="black-text fw-400">{{
                      dataText.e2CVariantenomCbAjoutoccurrence2Texte
                    }}</span>
                                    </template>
                                    <template v-else>
                    <span class="black-text fw-400">{{
                      dataText.e2CVariantenomCbRetraitoccurrence2Texte
                    }}</span>
                                    </template>
                                </label>
                            </p>
                        </div>
                        <app-section-variante-nom-2
                                :isniForm="isniForm"
                                :hasError="hasError"
                                v-show="isniForm.donneeIdentification.variante.idVariante2.check"
                                :dataText="dataText"
                        >
                        </app-section-variante-nom-2>

                        <div class="col s12">
                            <p>
                                <label>
                                    <input
                                            type="checkbox"
                                            :value="true"
                                            v-model="
                      isniForm.donneeIdentification.variante.idVariante3.check
                    "
                                    />
                                    <template
                                            v-if="
                      !isniForm.donneeIdentification.variante.idVariante3.check
                    "
                                    >
                    <span class="black-text fw-400">{{
                      dataText.e2CVariantenomCbAjoutoccurrence3Texte
                    }}</span>
                                    </template>
                                    <template v-else>
                    <span class="black-text fw-400">{{
                      dataText.e2CVariantenomCbRetraitoccurrence3Texte
                    }}</span>
                                    </template>
                                </label>
                            </p>
                        </div>

                        <app-section-variante-nom-3
                                :isniForm="isniForm"
                                :hasError="hasError"
                                v-show="isniForm.donneeIdentification.variante.idVariante3.check"
                                :dataText="dataText"
                        >
                        </app-section-variante-nom-3>

                        <div class="col s12">
                            <p>
                                <label>
                                    <input
                                            type="checkbox"
                                            :value="true"
                                            v-model="
                      isniForm.donneeIdentification.variante.idVariante4.check
                    "
                                    />
                                    <template
                                            v-if="
                      !isniForm.donneeIdentification.variante.idVariante4.check
                    "
                                    >
                    <span class="black-text fw-400">{{
                      dataText.e2CVariantenomCbAjoutoccurrence4Texte
                    }}</span>
                                    </template>
                                    <template v-else>
                    <span class="black-text fw-400">{{
                      dataText.e2CVariantenomCbRetraitoccurrence4Texte
                    }}</span>
                                    </template>
                                </label>
                            </p>
                        </div>
                        <app-section-variante-nom-4
                                :isniForm="isniForm"
                                :hasError="hasError"
                                v-show="isniForm.donneeIdentification.variante.idVariante4.check"
                                :dataText="dataText"
                        >
                        </app-section-variante-nom-4>
                    </div>
                </div>
                <!-- {{ fin Variante au nom}} -->
            </div>
        </div>
    </div>
</template>

<script>
    // import M from "materialize-css";
    import Date from "./../elements/Date";
    import Icon from "./../elements/Icon";
    import IsniTooltip from "../elements/IsniTooltip";
    import ListCities from "./../elements/ListCities";
    import IdentifiantNom1 from "./../sections/step2/IdentifiantNom1";
    import IdentifiantNom2 from "./../sections/step2/IdentifiantNom2";
    import VarianteNom1 from "./../sections/step2/VarianteNom1";
    import VarianteNom2 from "./../sections/step2/VarianteNom2";
    import VarianteNom3 from "./../sections/step2/VarianteNom3";
    import VarianteNom4 from "./../sections/step2/VarianteNom4";

    export default {
        name: "Step2",
        props: {
            isniForm: {
                type: Object,
                required: true
            },
            dataText: {
                type: Object,
                required: true
            },
            dataSelect: {
                type: Object,
                required: true
            },
            "data-position": {
                type: String
            },
            "data-tooltip": {
                type: String
            },
            hasError: Boolean,
            errorDetail: {
                type: Object
            }
        },
        data() {
            return {
                canadaB: false,
                canadaR: false,
                limitDiPersoNationalite: 3,
                limitImusic: 3,
                indNomReelChanged: false
            };
        },
        components: {
            Icon,
            IsniTooltip,
            "app-date": Date,
            "app-list-cities": ListCities,
            "app-section-id-name-1": IdentifiantNom1,
            "app-section-id-name-2": IdentifiantNom2,
            "app-section-variante-nom-1": VarianteNom1,
            "app-section-variante-nom-2": VarianteNom2,
            "app-section-variante-nom-3": VarianteNom3,
            "app-section-variante-nom-4": VarianteNom4,
        },
        methods: {
            disableUnselectedListItems($elems) {
                for (let i = 1; i < $elems.length ; i++) {
                    const $elem = $elems[i];
                    if (!$elem.classList.contains('selected')) {
                        $elem.classList.add('disabled');
                        $elem.querySelector('input[type=checkbox]').setAttribute('disabled', 'true');
                    }
                }
            },
            enableAllItemLists($elems) {
                for (let i = 1; i < $elems.length ; i++) {
                    const $elem = $elems[i];
                    if (!$elem.classList.contains('selected')) {
                        $elem.classList.remove('disabled');
                        $elem.querySelector('input[type=checkbox]').removeAttribute('disabled');
                    }
                }
            },
            onChangeCountryBirth(event) {
                if (event.target.value === "CA") {
                    this.canadaB = true;
                } else {
                    this.canadaB = false;
                    this.isniForm.donneeIdentification.lieu.naissance.region = "";
                    this.isniForm.donneeIdentification.lieu.naissance.loCode = "";
                    this.isniForm.donneeIdentification.lieu.naissance.ville = "";
                }
            },
            onChangeCountryResidence(event) {
                if (event.target.value === "CA") {
                    this.canadaR = true;
                } else {
                    this.canadaR = false;
                    this.isniForm.donneeIdentification.lieu.residence.region = "";
                    this.isniForm.donneeIdentification.lieu.residence.loCode = "";
                    this.isniForm.donneeIdentification.lieu.residence.ville = "";
                }
            },
            onChangeRegion(lieu) {
              this.isniForm.donneeIdentification.lieu[lieu].loCode = "";
              this.isniForm.donneeIdentification.lieu[lieu].ville = "";
            },
            validateCitySelection(selectedCity, lieu) {
                if (selectedCity && typeof selectedCity === "object") {
                    this.isniForm.donneeIdentification.lieu[lieu].loCode = selectedCity.loCode || '';
                    this.isniForm.donneeIdentification.lieu[lieu].ville = selectedCity.cityName;
                }
            },
            /*
             * Villes du Quebec jamais devront envoyer un loCode, meme si ce loCode a venu deja rempli
             **/
            validateCityQuebec(lieu) {
              this.isniForm.donneeIdentification.lieu[lieu].loCode = '';
            },
            verifyLimitDiPersoNationalite() {
                try {
                    const $elems = this.$el.querySelectorAll('.di-perso-nationalite-container ul li');
                    const size = this.isniForm.donneeIdentification.diPersoNationalite.length;
                    if (size >= this.limitDiPersoNationalite) {
                        this.disableUnselectedListItems($elems);
                    } else {
                        this.enableAllItemLists($elems);
                    }
                } catch(e) {
                    console.debug('[WARN - verifyLimitDiPersoNationalite] e: ' + e);
                    return;
                }
            },
            verifyLimitImusic() {
                try {
                    const $elems = this.$el.querySelectorAll('.imusic-container ul li');
                    const size = this.isniForm.donneeIdentification.instrumentMusic.length;
                    if (size >= this.limitImusic) {
                        this.disableUnselectedListItems($elems);
                    } else {
                        this.enableAllItemLists($elems);
                    }
                } catch(e) {
                    console.debug('[WARN - verifyLimitImusic] e: ' + e);
                    return;
                }
            }
        },
        updated: function () {

            //  M.AutoInit();
            if (this.indNomReelChanged) {
              this.$refs['reelNomOuPseudo-false'].click();
              this.indNomReelChanged = false;
            }
        },
        created() {
            // enabled CanadaR and canadaB when whe arrive in the form with the value already filled
            this.canadaR = this.isniForm.donneeIdentification.lieu.residence.pays === "CA";
            this.canadaB = this.isniForm.donneeIdentification.lieu.naissance.pays === "CA";
        },
        watch: {
            // isniForm.reelnom.check or isniForm.pseudo.check must be set when indNomReel changes
            'isniForm.donneeIdentification.indNomReel': function() {
                this.indNomReelChanged = true;
            }
        }
    };
</script>
<style lang="scss" scoped>
    .h4-title{
        font-size: 2.28rem;
        line-height: 110%;
        margin: 1.52rem 0 .912rem 0;
    }
    .bt {
        border-top: 10px solid #039be5;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }
    .pa-instrument{
        top: -40px;
    }
    @media only screen and (min-width : 600px) {
        .dynamicTooltip {
            z-index: 1;
        }
    }
</style>
