<!--
Composant Date
Affichage les champs date de naissance et de deces dans le formulaires


-->
<template>
  <!-- {{DATE Naissance & Deces}} -->
  <div class="">
    <!-- {{ date de naissance }} -->
    <div class="col s12 m6 l6">
      <p class="light-blue-text text-darken-4">
        {{ dataText.e2PIdpubliqueDtnSoustitreTexte }}
        <Isni-Tooltip :content="dataText.e2PIdpubliqueDtnSoustitreAc">
          <icon iconText="help"/>
        </Isni-Tooltip>
      </p>
      <div class="col s12 l4">
        <label :class="[ (hasError && (errorDetail.naissanceAnnee || errorDetail.naissanceAll)) ? 'error' : 'black-text']">{{
          dataText.e2PIdpubliqueDtnInputAnneeLabel
        }}</label>
        <input
          ref="naissanceAnnee"
          type="number"
          name="anneeN"
          min="0"
          title="Entre 0 et 2020"
          max="2020"
          class="validate"
          v-model="isniForm.donneeIdentification.date.naissance.annee"
          @blur="onExit('naissanceAnnee', 'naissance')"
        />
      </div>
      <div class="col s12 l4">
        <label :class="[ (hasError && errorDetail.naissanceAll) ? 'error' : 'black-text']">{{
          dataText.e2PIdpubliqueDtnListeMoisLabel
        }}</label>
        <select v-model="isniForm.donneeIdentification.date.naissance.mois">
          <option value disabled selected>{{ dataText.eCListeTexteParDefaut }}</option>
          <option value=""></option>
          <option
            v-for="(ref, index) in dataSelect.RefMois"
            :value="index"
            :key="index"
            >{{ ref }}</option>
        </select>
      </div>
      <div class="col s12 l4">
        <label :class="[ (hasError && errorDetail.naissanceAll) ? 'error' : 'black-text']">{{
          dataText.e2PIdpubliqueDtnListeJourLabel
        }}</label>
        <select v-model="isniForm.donneeIdentification.date.naissance.jour">
          <option value disabled selected>{{ dataText.eCListeTexteParDefaut }}</option>
          <option value=""></option>
          <!-- TODO gestion fevrier -->
          <!-- v-if="isniForm.donneeIdentification.date_naissance.mois == '2'" -->
          <template>
            <option v-for="item in 31" :key="item" :value="item">{{ item }}</option>
          </template>
        </select>
      </div>
    </div>

    <!-- {{ date de deces }} -->
    <div class="col s12 m6 l5 right">
      <p class="light-blue-text text-darken-4">
        {{ dataText.e2PIdpubliqueDtdSoustitreTexte }}
        <Isni-Tooltip :content="dataText.e2PIdpubliqueDtdSoustitreAc">
          <icon iconText="help"/>
        </Isni-Tooltip>
      </p>
      <div class="col s12 l4">
        <label :class="[ (hasError && (errorDetail.decesAnnee || errorDetail.decesAll)) ? 'error' : 'black-text']">{{
          dataText.e2PIdpubliqueDtdInputAnneeLabel
        }}</label>
        <input
          ref="decesAnnee"
          type="number"
          name="anneeD"
          min="0"
          title="Entre 0 et 2020"
          max="2020"
          class="validate"
          v-model="isniForm.donneeIdentification.date.deces.annee"
          @blur="onExit('naissanceAnnee', 'deces')"
        />
      </div>
      <div class="col s12 l4">
        <label :class="[ (hasError && errorDetail.decesAll) ? 'error' : 'black-text']">{{
          dataText.e2PIdpubliqueDtdListeMoisLabel
        }}</label>
        <select v-model="isniForm.donneeIdentification.date.deces.mois">
          <option value disabled selected>{{ dataText.eCListeTexteParDefaut }}</option>
          <option value=""></option>
          <option
            v-for="(ref, index) in dataSelect.RefMois"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
      </div>
      <div class="col s12 l4">
        <label :class="[ (hasError && errorDetail.decesAll) ? 'error' : 'black-text']">{{
          dataText.e2PIdpubliqueDtdListeJourLabel
        }}</label>
        <select v-model="isniForm.donneeIdentification.date.deces.jour">
          <option value disabled selected>{{ dataText.eCListeTexteParDefaut }}</option>
          <option value=""></option>
          <option v-for="item in 31" :key="item" :value="item">{{
            item
          }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon";
import IsniTooltip from "./IsniTooltip";

export default {
  name: "Date",
  // props: ["isniForm", "data-position", "data-delay", "data-tooltip"],
  components: { Icon, IsniTooltip },
  props: {
    dataText: {
      type: Object,
      required: true
    },
    isniForm: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    "data-position": {
      type: String
    },
    "data-tooltip": {
      type: String
    },
    hasError: Boolean,
    errorDetail: {
      type: Object
    }
  },
  data() {
    return {
      // year: number,
      // month: number,
      // day: number
    };
  },
  computed: {
    // dateFormat: function () {
    //
    //   this.year = this.isniForm.donneeIdentification.date_naissance.annee
    //   this.month = this.isniForm.donneeIdentification.date_naissance.mois
    //   this.day = isniForm.donneeIdentification.date_naissance.jour
    //   this.birthdayDate = this.year + this.month + this.day
    //   // return something
    //   return console.debug(this.birthdayDate);
    // }
  },
  methods: {
    onExit(elemRef, modalityDate) {
      if (this.$refs[elemRef]) {
        this.$refs[elemRef].value = this.isniForm.donneeIdentification.date[modalityDate].annee;
      }
    },
    // TODO: dynamically change day
    // getLastDay: function(modalityDate) {
    //   console.debug('getNewLastDay');
    //   try {
    //     const _year = this.isniForm.donneeIdentification.date[modalityDate].annee;
    //     const _month = this.isniForm.donneeIdentification.date[modalityDate].mois.split('_')[1];
    //     return (new Date(_year, _month, 0)).getDate();
    //   } catch(e) {
    //     return 31;
    //   }
    // },
  }
};
</script>

<style></style>
